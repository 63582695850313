import React from 'react'
import PropTypes from 'prop-types'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'

/**
 * Usage :
 *
 *    <Field
        name="rememberMe"
        component={RenderCheckbox}
        label="Remember me ?"
      />
 */

const RenderCheckbox = ({
  input,
  label,
  meta: { touched, error },
  isDisabled,
  customStyle,
  fullWidth,
}) => (
  <FormControl error={touched && error !== undefined} fullWidth={fullWidth}>
    <FormControlLabel
      control={
        <Checkbox
          checked={!!input.value}
          onChange={input.onChange}
          disabled={isDisabled}
          style={customStyle}
          name={input.name}
        />
      }
      label={label}
    />
    {touched && error && <FormHelperText>{error}</FormHelperText>}
  </FormControl>
)

RenderCheckbox.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    onChange: PropTypes.func,
    name: PropTypes.string,
  }).isRequired,
  label: PropTypes.string,
  isDisabled: PropTypes.bool,
  customStyle: PropTypes.shape({}),
  meta: PropTypes.shape({
    touch: PropTypes.bool,
    touched: PropTypes.bool,
    invalid: PropTypes.bool,
    error: PropTypes.string,
  }),
  fullWidth: PropTypes.bool,
}

RenderCheckbox.defaultProps = {
  label: '',
  meta: {},
  isDisabled: false,
  customStyle: {},
  fullWidth: false,
}

export default RenderCheckbox
