import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { withStyles, Paper } from '@material-ui/core'
import i18n from 'providers/i18n/I18nProvider'
import SimpleTable from 'components/shared/Tables/SimpleTable'
import FirmsFiltersContainer from 'components/Firms/FirmsFilters/FirmsFiltersContainer'
import FirmsListTableHead from './FirmsListTableHead'
import FirmsListTableBody from './FirmsListTableBody'
import styles from './FirmsListStyles'

const FirmsList = ({
  classes,
  rows,
  searchParams,
  isLoading,
  handleChangePage,
  handleChangePerPage,
  handleSearchChange,
  handleFiltersChange,
  handleFacetFiltersChange,
  handleEditFirm,
  hasPackages,
  showAllFilters,
  handleChangeSort,
  isFirmAvailable,
  firmUnavailabilityPeriods,
}) => {
  const { page, total, rowsPerPage, orderBy, sort } = searchParams

  return (
    <Paper className={classes.root}>
      <FirmsFiltersContainer
        handleSearchChange={handleSearchChange}
        handleFiltersChange={handleFiltersChange}
        handleFacetFiltersChange={handleFacetFiltersChange}
        showAllFilters={showAllFilters}
      />
      <SimpleTable
        rows={rows}
        TableHeadContent={FirmsListTableHead}
        page={page}
        orderBy={orderBy}
        sort={sort}
        total={total}
        isLoading={isLoading}
        handleChangePage={handleChangePage}
        rowsPerPage={rowsPerPage}
        handleChangePerPage={handleChangePerPage}
        TableBodyContent={FirmsListTableBody}
        onClick={handleEditFirm}
        additionalProps={{
          hasPackages,
          isFirmAvailable,
          firmUnavailabilityPeriods,
        }}
        handleChangeSort={handleChangeSort}
      />
    </Paper>
  )
}

FirmsList.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string.isRequired,
  }).isRequired,
  rows: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  searchParams: PropTypes.shape({
    query: PropTypes.string.isRequired,
    orderBy: PropTypes.string,
    sort: PropTypes.string,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  handleChangePerPage: PropTypes.func.isRequired,
  handleSearchChange: PropTypes.func.isRequired,
  handleFiltersChange: PropTypes.func.isRequired,
  handleFacetFiltersChange: PropTypes.func.isRequired,
  handleEditFirm: PropTypes.func.isRequired,
  hasPackages: PropTypes.bool.isRequired,
  showAllFilters: PropTypes.bool.isRequired,
  handleChangeSort: PropTypes.func.isRequired,
  isFirmAvailable: PropTypes.bool,
  firmUnavailabilityPeriods: PropTypes.arrayOf(PropTypes.shape({})),
}

FirmsList.defaultProps = {
  isFirmAvailable: undefined,
  firmUnavailabilityPeriods: undefined,
}

export default compose(i18n, withStyles(styles))(FirmsList)
