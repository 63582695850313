import React, { useState } from 'react'
import PropTypes from 'prop-types'
import i18n from 'providers/i18n/I18nProvider'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core'
import ConfirmDialogActionsContent from 'components/shared/ConfirmDialog/DialogActionsContent'
import SimpleDialogActionsContent from './DialogActionsContent'

const SimpleDialog = ({
  title,
  children,
  dialogProps,
  onClose,
  disableClose,
  type,
  translate,
  customActions,
}) => {
  const [open, setOpen] = useState(true)

  const handleClose = event => {
    if (!disableClose) {
      setOpen(false)
    }
    onClose(event)
  }

  const enableClose = () => (disableClose ? () => {} : handleClose)

  const renderActionsContent = () => {
    if (type === 'confirm') {
      return (
        <ConfirmDialogActionsContent
          translate={translate}
          handleClose={handleClose}
        />
      )
    }

    if (type === 'custom') {
      return customActions
    }

    return (
      <>
        {customActions}
        <SimpleDialogActionsContent
          translate={translate}
          handleClose={handleClose}
        />
      </>
    )
  }

  return (
    <Dialog
      open={open}
      onClose={enableClose()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      {...dialogProps}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>{renderActionsContent()}</DialogActions>
    </Dialog>
  )
}

SimpleDialog.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['simple', 'confirm', 'custom']),
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  dialogProps: PropTypes.shape({}),
  disableClose: PropTypes.bool,
  customActions: PropTypes.node,
}

SimpleDialog.defaultProps = {
  type: 'simple',
  dialogProps: {},
  disableClose: false,
  customActions: undefined,
}

export default i18n(SimpleDialog)
