import { reset, change } from 'redux-form'
import {
  all,
  call,
  put,
  select,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects'
import { saveAs } from 'file-saver'
import { dateFormatter } from 'helpers/date'
import { getJobIdFromIri } from 'helpers/utils/job/job'
import { handleRequest } from 'helpers/store/sagasHelpers'
import translate from 'providers/i18n/translateService'
import billingClient from 'services/httpClient/billingClient'
import engineClient from 'services/httpClient/engineClient'
import n8nClient from 'services/httpClient/n8nClient'
import {
  FIELD_INVOICE_ITEMS,
  FIELD_INVOICE_SUBTOTAL,
  FIELD_INVOICE_TAX_TOTAL,
  FIELD_INVOICE_TOTAL,
  LM_FRANCE,
  NATURE_TRAVEL_COMPENSATION,
} from 'constants/invoices'
import { ISO_SHORT, DATE_TIME } from 'constants/date'
import { ERROR, INFO, SUCCESS } from 'constants/variant'
import { EDIT_INVOICE_FORM, FILTER_INVOICES_LIST_FORM } from 'constants/forms'
import {
  INVOICE_CONTEST_TRANSITION_REQ,
  INVOICE_CANCEL_CONTEST_TRANSITION_REQ,
  INVOICE_REEDITION_TRANSITION_REQ,
  INVOICE_PAY_TRANSITION_REQ,
  invoiceReeditionTransition,
} from 'store/invoices/transitions/invoiceTransitionActions'
import { showNotification } from 'store/Application/ApplicationActions'
import { generateSupportingDocuments, refreshJob } from 'store/jobs/jobActions'
import {
  formattedJobIdSelector,
  invoiceIdSelector,
  acceptedProSelector,
} from 'store/jobs/jobSelectors'
import proApiClient from 'services/httpClient/proApiClient'
import { getSingleFirm } from 'store/firms/firmActions'
import {
  GET_INVOICES_LIST,
  GET_INVOICES_EXPORT,
  RESET_FILTER_INVOICE,
  getInvoicesList,
  getInvoicesExport,
  GET_SINGLE_INVOICE,
  getInvoiceDetails,
  INVOICE_CART_ADD_ITEM,
  INVOICE_CART_PROCESS_TOTALS,
  INVOICE_CART_DELETE_ITEM,
  INVOICE_CART_UPDATE_ITEM,
  invoiceCartProcessTotals,
  SET_SUBJECT_TO_VAT,
  updateInvoice,
  UPDATE_INVOICE,
  GET_JOB_INVOICES_LIST,
  getJobInvoicesList,
  EXPORT_INVOICES_LIST,
  CREATE_TRAVEL_COMPENSATION_PURCHASE_INVOICE,
  CREATE_PURCHASE_INVOICE,
  createTravelCompensationPurchaseInvoice,
  createPurchaseInvoice,
  generateInvoiceAfterSale,
  GENERATE_INVOICE_AFTERSALE_REQ,
  CREATE_INVOICE_AFTERSALE_REQ,
  createInvoiceAfterSale,
} from './invoiceActions'
import {
  cartItemsSelector,
  cartSubtotalSelector,
  cartTaxTotalSelector,
  cartTotalSelector,
  currentInvoiceCartSelector,
  currentInvoiceSelector,
  invoiceIriSelector,
  searchParamsSelector,
  getAllInvoicesListFormValues,
  additionalRebillingCustomersInLineSelector,
} from './invoiceSelectors'

const buildInvoiceParams = searchParams => {
  const urlParams = {}

  if (searchParams.jobId) {
    urlParams.jobId = searchParams.jobId
  }

  if (searchParams.number) {
    urlParams.number = searchParams.number
  }

  if (searchParams.orderNumber) {
    urlParams.orderNumber = searchParams.orderNumber
  }

  if (searchParams.proName) {
    urlParams.proName = searchParams.proName
  }

  if (searchParams.homeOwnerName) {
    urlParams.homeOwnerName = searchParams.homeOwnerName
  }

  if (searchParams.export) {
    urlParams.pagination = false
  }
  if (!searchParams.export && searchParams.rowsPerPage) {
    urlParams.page = searchParams.page + 1
    urlParams.perPage = searchParams.rowsPerPage
  }

  if (searchParams.orderBy && searchParams.sort) {
    urlParams[`order[${searchParams.orderBy}]`] = searchParams.sort
  }

  if (searchParams.types) {
    urlParams.type = []
    searchParams.types.forEach(type =>
      urlParams.type.push(`${type.charAt(0).toLowerCase()}${type.slice(1)}`),
    )
  }

  if (searchParams.natures) {
    urlParams.nature = []
    searchParams.natures.forEach(nature => urlParams.nature.push(nature))
  }

  if (searchParams.states) {
    urlParams.state = []
    searchParams.states.forEach(state => urlParams.state.push(state))
  }

  if (searchParams.issuedAt) {
    urlParams['issuedAt[after]'] = dateFormatter(
      searchParams.issuedAt[0],
      ISO_SHORT,
    )
    urlParams['issuedAt[before]'] = dateFormatter(
      searchParams.issuedAt[1],
      DATE_TIME,
    )
  }

  return urlParams
}

export function* billingAPI(requestActions, promise, actionParams, key) {
  try {
    return yield* handleRequest({
      requestActions,
      promise,
      actionParams,
    })
  } catch (e) {
    yield put(
      showNotification({
        payload: {
          message: translate(`invoice.${key}.req_failure`),
          messageType: ERROR,
        },
      }),
    )
  }
  return undefined
}

function* handleGetInvoicesList(searchParams) {
  const data = { params: buildInvoiceParams(searchParams) }
  const opts = { triggerModalLoader: true }
  const url = 'invoices'
  const p = call(billingClient.get, url, data)
  yield* billingAPI(getInvoicesList, p, opts, 'invoice_list')
}

function* handleGetJobInvoicesList() {
  const jobId = yield select(formattedJobIdSelector)
  const data = { params: { jobId } }
  const opts = { triggerModalLoader: true }
  const url = 'invoices'
  const p = call(billingClient.get, url, data)
  yield* billingAPI(getJobInvoicesList, p, opts, 'invoice_list')
}

function* handleGetInvoicesExport(searchParams) {
  const body = {
    responseType: 'blob',
    headers: { Accept: 'text/csv' },
    params: buildInvoiceParams(searchParams),
  }
  const opts = { triggerModalLoader: true }
  const url = 'purchase-invoices.csv?pagination=false'
  const p = call(billingClient.get, url, body)

  const response = yield* billingAPI(
    getInvoicesExport,
    p,
    opts,
    'invoice_export',
  )

  // invoke 'Save As' dialog
  saveAs(response.data, 'invoices_export.csv')
}

function* handleGetInvoiceDetails({ invoiceId, jobId }) {
  const opts = { triggerModalLoader: true }
  const url = `purchase-invoices/${invoiceId}`
  const p = call(billingClient.get, url)
  yield* billingAPI(getInvoiceDetails, p, opts, 'invoice_details')
  yield put(refreshJob.request({ jobId }))
}

export function* handleResetFilterInvoice() {
  // reset redux form
  yield put(reset(FILTER_INVOICES_LIST_FORM))

  // force search reloading with default params
  const searchParams = yield select(searchParamsSelector)
  yield put(getInvoicesList.request(searchParams))
}

export function* handleInvoiceCartAddItem() {
  yield refreshInvoiceCartItems()
}

export function* handleInvoiceCartUpdateItem() {
  yield refreshInvoiceCartItems()

  yield put(invoiceCartProcessTotals())
}

export function* handleInvoiceCartDeleteItem() {
  yield refreshInvoiceCartItems()

  yield put(invoiceCartProcessTotals())
}

export function* handleSetSubjectToVat() {
  yield handleInvoiceCartProcessTotal()
}

function* refreshInvoiceCartItems() {
  const items = yield select(cartItemsSelector)

  yield put(change(EDIT_INVOICE_FORM, FIELD_INVOICE_ITEMS, items))
}

export function* handleInvoiceCartProcessTotal() {
  const orderSubtotal = yield select(cartSubtotalSelector)
  const orderTaxTotal = yield select(cartTaxTotalSelector)
  const orderTotal = yield select(cartTotalSelector)

  yield put(change(EDIT_INVOICE_FORM, FIELD_INVOICE_SUBTOTAL, orderSubtotal))
  yield put(change(EDIT_INVOICE_FORM, FIELD_INVOICE_TAX_TOTAL, orderTaxTotal))
  yield put(change(EDIT_INVOICE_FORM, FIELD_INVOICE_TOTAL, orderTotal))
}

export function* handleUpdateInvoice({ validate }) {
  const invoiceIri = yield select(invoiceIriSelector)
  const currentInvoice = yield select(currentInvoiceSelector)
  const cart = yield select(currentInvoiceCartSelector)
  const typeUpdate = validate ? 'update_and_validate' : 'update'
  const data = { '@id': currentInvoice['@id'], ...cart }
  const options = { triggerModalLoader: true }
  const p = call(billingClient.put, invoiceIri, data)

  yield* billingAPI(updateInvoice, p, options, 'invoice_update')

  if (validate) {
    const invoiceId = yield select(invoiceIdSelector)
    // Call the invoice transition saga
    yield put(invoiceReeditionTransition.request({ invoiceId }))
  }

  yield put(
    showNotification({
      payload: {
        message: translate(`invoice.invoice_update.${typeUpdate}_success`),
        messageType: INFO,
      },
    }),
  )
}

export function* handleExportInvoicesList() {
  const values = yield select(getAllInvoicesListFormValues)
  const body = {
    responseType: 'blob',
    headers: { Accept: 'text/csv' },
    params: buildInvoiceParams({ export: true, ...values }),
  }
  const opts = { triggerModalLoader: true }
  const url = 'invoices.csv'
  const p = call(billingClient.get, url, body)

  const response = yield* billingAPI(
    getInvoicesExport,
    p,
    opts,
    'invoice_export',
  )

  saveAs(response.data, 'invoices_export.csv')
}

export function* handleCreateTravelCompensationPurchaseInvoiceRequest({
  jobId,
  proId,
}) {
  const url = `/jobs/${jobId}/create-travel-compensation-purchase-invoice/${proId}`

  try {
    yield* handleRequest({
      requestActions: createTravelCompensationPurchaseInvoice,
      promise: call(engineClient.post, url),
      actionParams: {
        triggerModalLoader: true,
      },
    })
  } catch (e) {
    console.error(e)
  }
}

export function* handleCreateTravelCompensationPurchaseInvoiceSuccess() {
  yield put(
    showNotification({
      payload: {
        message: translate(
          'resources.invoice.dialog.create_displacement_invoice.notif.success',
        ),
        messageType: SUCCESS,
      },
    }),
  )
}

export function* handleCreateTravelCompensationPurchaseInvoiceFailure() {
  yield put(
    showNotification({
      payload: {
        message: translate(
          'resources.invoice.dialog.create_displacement_invoice.notif.failure',
        ),
        messageType: ERROR,
      },
    }),
  )
}

export function* handleCreatePurchaseInvoiceRequest({ jobId }) {
  // @TODO: change call to billingClient to do actually something useful
  const url = `/to_change/${jobId}`

  try {
    yield* handleRequest({
      requestActions: createPurchaseInvoice,
      promise: call(billingClient.get, url),
      actionParams: {
        triggerModalLoader: true,
      },
    })
  } catch (e) {
    console.error(e)
  }
}

export function* handleCreatePurchaseInvoiceSuccess() {
  yield put(
    showNotification({
      payload: {
        message: translate(
          'resources.invoice.dialog.create_displacement_invoice.notif.success',
        ),
        messageType: SUCCESS,
      },
    }),
  )
}

export function* handleCreatePurchaseInvoiceFailure() {
  yield put(
    showNotification({
      payload: {
        message: translate(
          'resources.invoice.dialog.create_displacement_invoice.notif.failure',
        ),
        messageType: ERROR,
      },
    }),
  )
}

export function* handleGenerateInvoiceAfterSale() {
  const jobIri = yield select(formattedJobIdSelector)
  const jobId = getJobIdFromIri(jobIri)
  yield* handleRequest({
    requestActions: generateInvoiceAfterSale,
    promise: call(
      billingClient.get,
      `additional-invoice/${jobId}/form-configuration`,
    ),
    actionParams: { triggerModalLoader: true },
  })
}

export function* handleCreateInvoiceAfterSale({ data }) {
  const jobIri = yield select(formattedJobIdSelector)
  const rebillingCustomersOptions = yield select(
    additionalRebillingCustomersInLineSelector,
  )
  const jobId = getJobIdFromIri(jobIri)
  let rebillingTravelCompensation = false

  yield all(
    data?.map(
      ({
        invoiceNatures,
        invoiceLineTypes,
        provider,
        amount,
        rebilling,
        rebillingCustomer,
      }) => {
        const isRebilling = rebilling === 'true'

        let sanitizedAmount = amount
        if (typeof amount === 'string') {
          sanitizedAmount = parseFloat(amount.replace(',', '.'))
        }

        const sendData = {
          nature: invoiceNatures,
          type: invoiceLineTypes,
          provider: {
            id: provider,
          },
          amount: {
            value: sanitizedAmount,
          },
        }

        if (
          invoiceNatures === NATURE_TRAVEL_COMPENSATION &&
          isRebilling &&
          rebillingCustomersOptions[rebillingCustomer] === LM_FRANCE
        ) {
          rebillingTravelCompensation = true
        }

        if (isRebilling) {
          sendData.rebilling = isRebilling
          sendData.rebillingCustomer = {
            id: rebillingCustomer,
          }
        }

        return handleRequest({
          requestActions: createInvoiceAfterSale,
          promise: call(
            billingClient.post,
            `additional-invoice/${jobId}/create`,
            sendData,
          ),
          actionParams: {
            triggerModalLoader: true,
            rebillingTravelCompensation,
          },
        })
      },
    ),
  )
}

export function* handleCreateInvoiceAfterSaleSuccess({ actionParams }) {
  const jobIri = yield select(formattedJobIdSelector)
  yield put(
    showNotification({
      payload: {
        message: translate('resources.invoice.create_purchase_invoice.success'),
        messageType: SUCCESS,
      },
    }),
  )
  yield put(getJobInvoicesList.request())
  if (actionParams.rebillingTravelCompensation) {
    yield handleRequest({
      requestActions: generateSupportingDocuments,
      promise: call(n8nClient.post, '/webhook/generate-supporting-documents', {
        jobId: getJobIdFromIri(jobIri),
      }),
      actionParams: { triggerModalLoader: true },
    })
  }
}

export function* handleCreateInvoiceAfterSaleFailure() {
  yield put(
    showNotification({
      payload: {
        message: translate('resources.invoice.create_purchase_invoice.failure'),
        messageType: ERROR,
      },
    }),
  )
}

export function* handleGetJobInvoicesListSuccess() {
  const acceptedPro = yield select(acceptedProSelector)
  const firmId = acceptedPro?.id

  if (!firmId) {
    return
  }

  yield* handleRequest({
    requestActions: getSingleFirm,
    promise: call(proApiClient.get, `/api/firms/${firmId}`),
  })
}

export default function*() {
  yield all([
    takeLatest(GET_INVOICES_LIST.REQUEST, handleGetInvoicesList),
    takeLatest(
      [
        GET_JOB_INVOICES_LIST.REQUEST,
        INVOICE_CONTEST_TRANSITION_REQ.SUCCESS,
        INVOICE_CANCEL_CONTEST_TRANSITION_REQ.SUCCESS,
        INVOICE_REEDITION_TRANSITION_REQ.SUCCESS,
        INVOICE_PAY_TRANSITION_REQ.SUCCESS,
        CREATE_TRAVEL_COMPENSATION_PURCHASE_INVOICE.SUCCESS,
        CREATE_PURCHASE_INVOICE.SUCCESS,
      ],
      handleGetJobInvoicesList,
    ),
    takeLatest(GET_JOB_INVOICES_LIST.REQUEST, handleGetJobInvoicesListSuccess),
    takeLatest(GET_INVOICES_EXPORT.REQUEST, handleGetInvoicesExport),
    takeLatest(GET_SINGLE_INVOICE.REQUEST, handleGetInvoiceDetails),
    takeLatest(RESET_FILTER_INVOICE, handleResetFilterInvoice),
    takeLatest(INVOICE_CART_ADD_ITEM, handleInvoiceCartAddItem),
    takeLatest(INVOICE_CART_UPDATE_ITEM, handleInvoiceCartUpdateItem),
    takeLatest(INVOICE_CART_DELETE_ITEM, handleInvoiceCartDeleteItem),
    takeLatest(INVOICE_CART_PROCESS_TOTALS, handleInvoiceCartProcessTotal),
    takeLatest(SET_SUBJECT_TO_VAT, handleSetSubjectToVat),
    takeLatest(UPDATE_INVOICE.REQUEST, handleUpdateInvoice),
    takeLeading(EXPORT_INVOICES_LIST, handleExportInvoicesList),
    takeLatest(
      CREATE_TRAVEL_COMPENSATION_PURCHASE_INVOICE.REQUEST,
      handleCreateTravelCompensationPurchaseInvoiceRequest,
    ),
    takeLatest(
      CREATE_TRAVEL_COMPENSATION_PURCHASE_INVOICE.FAILURE,
      handleCreateTravelCompensationPurchaseInvoiceFailure,
    ),
    takeLatest(
      CREATE_TRAVEL_COMPENSATION_PURCHASE_INVOICE.SUCCESS,
      handleCreateTravelCompensationPurchaseInvoiceSuccess,
    ),
    takeLatest(
      CREATE_PURCHASE_INVOICE.REQUEST,
      handleCreatePurchaseInvoiceRequest,
    ),
    takeLatest(
      CREATE_PURCHASE_INVOICE.FAILURE,
      handleCreatePurchaseInvoiceFailure,
    ),
    takeLatest(
      CREATE_PURCHASE_INVOICE.SUCCESS,
      handleCreatePurchaseInvoiceSuccess,
    ),
    takeLatest(
      GENERATE_INVOICE_AFTERSALE_REQ.REQUEST,
      handleGenerateInvoiceAfterSale,
    ),
    takeLatest(
      CREATE_INVOICE_AFTERSALE_REQ.REQUEST,
      handleCreateInvoiceAfterSale,
    ),
    takeLatest(
      CREATE_INVOICE_AFTERSALE_REQ.SUCCESS,
      handleCreateInvoiceAfterSaleSuccess,
    ),
    takeLatest(
      CREATE_INVOICE_AFTERSALE_REQ.FAILURE,
      handleCreateInvoiceAfterSaleFailure,
    ),
  ])
}
