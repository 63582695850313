import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getFirmsList, getSingleFirm } from 'store/firms/firmActions'
import {
  firmsListSelector,
  searchParamsSelector,
} from 'store/firms/firmSelectors'
import { formatSearchParams } from 'helpers/search/searchParamsHelper'
import {
  addFacetFilter,
  buildTagFilters,
} from 'helpers/search/searchFirmsHelper'
import { ALL_PROS_LIST, PROS_WITH_PACKAGES_LIST } from 'constants/firms'
import { isLoadingSelector } from 'store/Application/ApplicationSelectors'
import { debounce } from 'lodash'
import FirmsList from './FirmsList'

const hasPackage = listName => listName === PROS_WITH_PACKAGES_LIST
const showAllFilters = listName => listName === ALL_PROS_LIST

const addTagFilters = listName => {
  const tagFilters =
    listName === PROS_WITH_PACKAGES_LIST ? ['availableForPackage'] : []

  return buildTagFilters(tagFilters)
}

const FirmsListContainer = ({
  getFirms,
  getFirm,
  firms,
  searchParams,
  listName,
  isLoading,
}) => {
  const loadFirms = ({ searchParams: searchParamsAlias }) => {
    getFirms({
      searchParams: {
        ...searchParamsAlias,
        tagFilters: addTagFilters(listName),
      },
    })
  }

  useEffect(() => {
    const { total, rowsPerPage, nbPages, ...params } = searchParams
    loadFirms({ searchParams: params })
  }, [])

  const handleChangeSort = orderBy => {
    let sort
    if (!searchParams.orderBy || searchParams.orderBy !== orderBy) {
      sort = 'desc'
    } else {
      sort = searchParams.sort === 'desc' ? 'asc' : 'desc'
    }

    return loadFirms(formatSearchParams({ ...searchParams, orderBy, sort }))
  }

  const handleEditFirm = firmId => {
    getFirm({ firmId })
  }

  const handleChangePerPage = event => {
    loadFirms(
      formatSearchParams({ ...searchParams, rowsPerPage: event.target.value }),
    )
  }

  const handleChangePage = page => {
    loadFirms(formatSearchParams({ ...searchParams, page }))
  }

  const handleFacetFiltersChange = (fieldName, values) => {
    loadFirms(
      formatSearchParams({
        ...searchParams,
        facetFilters: addFacetFilter(
          searchParams.facetFilters || [],
          fieldName,
          values ? values.map(element => element.value || element) : [],
        ),
      }),
    )
  }

  const handleFiltersChange = (event, value) => {
    loadFirms(
      formatSearchParams({
        ...searchParams,
        filters:
          event.target.name === 'paymentBlocked' && !value
            ? ''
            : `${event.target.name}:${value}`,
      }),
    )
  }

  const delaySearchFirms = debounce(({ value: query, name }) => {
    const data = { ...searchParams, query }

    loadFirms(formatSearchParams(data, name))
  }, 600)

  const handleSearchChange = event => {
    delaySearchFirms(event.target)
  }

  return (
    <FirmsList
      rows={firms}
      searchParams={searchParams}
      isLoading={isLoading}
      handleChangePage={handleChangePage}
      handleChangePerPage={handleChangePerPage}
      handleSearchChange={handleSearchChange}
      handleFacetFiltersChange={handleFacetFiltersChange}
      handleFiltersChange={handleFiltersChange}
      handleEditFirm={handleEditFirm}
      handleChangeSort={handleChangeSort}
      hasPackages={hasPackage(listName)}
      showAllFilters={showAllFilters(listName)}
    />
  )
}

FirmsListContainer.propTypes = {
  getFirms: PropTypes.func.isRequired,
  getFirm: PropTypes.func.isRequired,
  firms: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  listName: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  searchParams: PropTypes.shape({
    query: PropTypes.string.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    nbPages: PropTypes.number,
    orderBy: PropTypes.string,
    sort: PropTypes.string,
    facetFilters: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string))
      .isRequired,
  }).isRequired,
}

const mapStateToProps = state => ({
  firms: firmsListSelector(state),
  searchParams: searchParamsSelector(state),
  isLoading: isLoadingSelector(state),
})

const mapDispatchToProps = dispatch => ({
  getFirms: searchParams => dispatch(getFirmsList.request(searchParams)),
  getFirm: searchParams => dispatch(getSingleFirm.request(searchParams)),
})

export default connect(mapStateToProps, mapDispatchToProps)(FirmsListContainer)
