export default theme => ({
  urgentWord: {
    fontSize: 14,
    color: theme.palette.error.main,
    fontWeight: '600',
    margin: '0 24px 0 0',
  },
  warningAlert: {
    marginTop: '24px',
  },
  checkbox: {
    marginTop: '10px',
  },
})
