import React, { useEffect, useState } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import i18n from 'providers/i18n/I18nProvider'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { FIRM_COMMENT_PAGE } from 'constants/firms'
import {
  addFirmComment,
  getFirmCommentsList,
} from 'store/firmComments/firmCommentActions'
import {
  firmCommentsSearchParamsSelector,
  firmCommentsSearchResultSelector,
} from 'store/firmComments/firmCommentSelectors'
import { currentFirmIdSelector } from 'store/firms/firmSelectors'
import FirmComments from './FirmComments'

const FirmCommentsContainer = ({
  currentFirmId,
  firmCommentsSearchParams,
  firmCommentsSearchResult,
  getFirmsComments,
  addComment,
  translate,
}) => {
  const [state] = useState({
    firm: currentFirmId,
    page: firmCommentsSearchParams.page,
    perPage: firmCommentsSearchParams.perPage,
  })

  const loadComments = params => {
    getFirmsComments(params)
  }

  useEffect(() => {
    loadComments(state)
  }, [state])

  const handleChangePage = (event, page) => {
    const params = { ...firmCommentsSearchParams, page }
    loadComments(params)
  }

  const handleChangeRowsPerPage = event => {
    const params = {
      ...firmCommentsSearchParams,
      perPage: event.target.value,
      page: FIRM_COMMENT_PAGE,
    }
    loadComments(params)
  }

  const setLabelDisplayedRows = ({ from, to, count }) =>
    translate('navigation.page_range_info', {
      offsetBegin: from,
      offsetEnd: to,
      total: count,
    })

  return (
    <FirmComments
      onSubmit={values => addComment(values)}
      firmCommentsSearchResult={firmCommentsSearchResult}
      firmCommentsSearchParams={firmCommentsSearchParams}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      setLabelDisplayedRows={setLabelDisplayedRows}
    />
  )
}

FirmCommentsContainer.propTypes = {
  addComment: PropTypes.func.isRequired,
  getFirmsComments: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      firmId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  firmCommentsSearchParams: PropTypes.shape({
    perPage: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
  }).isRequired,
  firmCommentsSearchResult: PropTypes.shape({
    totalItems: PropTypes.number.isRequired,
    list: PropTypes.arrayOf(
      PropTypes.shape({
        author: PropTypes.string.isRequired,
        createdAt: PropTypes.string.isRequired,
        comment: PropTypes.string.isRequired,
        '@id': PropTypes.string.isRequired,
      }),
    ),
  }).isRequired,
  translate: PropTypes.func.isRequired,
  currentFirmId: PropTypes.string.isRequired,
}

const mapStateToProps = state => ({
  currentFirmId: currentFirmIdSelector(state),
  firmCommentsSearchResult: firmCommentsSearchResultSelector(state),
  firmCommentsSearchParams: firmCommentsSearchParamsSelector(state),
})

const mapDispatchToProps = dispatch => ({
  getFirmsComments: params => dispatch(getFirmCommentsList.request({ params })),
  addComment: values => dispatch(addFirmComment({ payload: values })),
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  i18n,
)(FirmCommentsContainer)
