import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import i18n from 'providers/i18n/I18nProvider'
import Notification from 'components/shared/Notification/Notification'
import {
  AppBar,
  CssBaseline,
  Drawer,
  IconButton,
  Toolbar,
  Typography,
} from '@material-ui/core'
import { Menu as MenuIcon } from '@material-ui/icons'
import Loader from 'components/shared/Loader/Loader'
import MenuNested from 'components/Menu/Menu'
import ChipStatusContainer from 'components/Jobs/JobDetails/ChipStatus/ChipStatusContainer'
import ToPendingCancelationLinkContainer from 'components/Jobs/JobDetails/ToPendingCancelationLink/ToPendingCancelationLinkContainer'
import GeozoneSelectorContainer from 'components/Dashboard/GeozoneSelector/GeozoneSelectorContainer'
import ToCancelLinkContainer from 'components/Jobs/JobDetails/ToCancelLink/ToCancelLinkContainer'
import {
  AFTER_SALE_ALL_TYPES,
  MANUAL_DIAGNOSTIC_AFTER_SALE_TYPE,
} from 'constants/Jobs'
import ToDoneLinkContainer from 'components/Jobs/JobDetails/ToDoneLink/ToDoneLinkContainer'

class Layout extends PureComponent {
  constructor(props) {
    super(props)
    this.contentRef = React.createRef()

    this.state = { open: true }
  }

  componentDidUpdate() {
    const { scrollTop } = this.props
    if (scrollTop === true) {
      this.contentRef.current.scrollTop = 0
    }
  }

  handleDrawerOpen = () => {
    this.setState({ open: true })
  }

  render() {
    const {
      classes,
      children,
      title,
      isLoading,
      isNotified,
      notificationMessage,
      messageType,
      currentJob,
      isManualAfterSaleDiag,
    } = this.props
    const { open } = this.state

    return (
      <div className={classes.root}>
        <Loader loading={isLoading} />
        <Notification
          open={isNotified}
          message={notificationMessage}
          messageType={messageType}
        />
        <CssBaseline />
        <AppBar
          position="absolute"
          className={classNames(classes.appBar, open && classes.appBarShift)}
        >
          <Toolbar disableGutters={!open} className={classes.toolbar}>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={this.handleDrawerOpen}
              className={classNames(
                classes.menuButton,
                open && classes.menuButtonHidden,
              )}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              className={classes.title}
            >
              {title}
              <ChipStatusContainer />
              {AFTER_SALE_ALL_TYPES.includes(currentJob.type) && (
                <ToCancelLinkContainer />
              )}
              {!AFTER_SALE_ALL_TYPES.includes(currentJob.type) && (
                <ToPendingCancelationLinkContainer />
              )}
              {currentJob.type === MANUAL_DIAGNOSTIC_AFTER_SALE_TYPE &&
                isManualAfterSaleDiag && <ToDoneLinkContainer />}
            </Typography>
            <GeozoneSelectorContainer />
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          classes={{
            paper: classNames(
              classes.drawerPaper,
              !open && classes.drawerPaperClose,
            ),
          }}
          open={open}
        >
          <div className={classes.toolbarIcon} />
          <MenuNested />
        </Drawer>
        <main className={classes.content} ref={this.contentRef}>
          <div className={classes.appBarSpacer} />
          {children}
        </main>
      </div>
    )
  }
}

Layout.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    appBar: PropTypes.string,
    appBarShift: PropTypes.string,
    toolbar: PropTypes.string,
    menuButton: PropTypes.string,
    menuButtonHidden: PropTypes.string,
    title: PropTypes.string,
    drawerPaper: PropTypes.string,
    drawerPaperClose: PropTypes.string,
    toolbarIcon: PropTypes.string,
    content: PropTypes.string,
    appBarSpacer: PropTypes.string,
    profile: PropTypes.string,
  }).isRequired,
  title: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isNotified: PropTypes.bool,
  notificationMessage: PropTypes.string,
  messageType: PropTypes.oneOf(['success', 'warning', 'error', 'info']),
  children: PropTypes.node.isRequired,
  scrollTop: PropTypes.bool,
  currentJob: PropTypes.shape({
    type: PropTypes.string,
  }).isRequired,
  isManualAfterSaleDiag: PropTypes.bool.isRequired,
}

Layout.defaultProps = {
  isNotified: false,
  notificationMessage: '',
  messageType: 'info',
  scrollTop: false,
}

export default i18n(Layout)
