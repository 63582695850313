import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core'
import Icon from 'components/shared/Icon/Icon'
import UploaderPopIn from './UploaderPopIn'
import styles from './UploaderStyles'

const UploaderBtn = ({
  acceptedFiles,
  label,
  classes,
  onSuccess,
  onValidation,
  reserveLabel,
  disabled,
  filesLimit,
  dropzoneText,
  advertizeText,
  checkboxNeeded,
  title,
  FormComponent,
  editIcon,
  formOption,
  variant,
  color,
  className,
}) => {
  const [displayPopIn, setDisplayPopIn] = useState(false)

  return (
    <>
      {editIcon ? (
        <Icon
          className={classes.editIcon}
          icon="edit.svg"
          onClick={() => setDisplayPopIn(true)}
        />
      ) : (
        <Button
          className={className || classes.buttonSecondary}
          onClick={() => setDisplayPopIn(true)}
          disabled={disabled}
          variant={variant}
          color={color}
        >
          {label}
        </Button>
      )}
      <UploaderPopIn
        acceptedFiles={acceptedFiles}
        onSuccessFileLoad={onSuccess}
        onValidation={onValidation}
        open={displayPopIn}
        onClose={() => setDisplayPopIn(false)}
        reserveLabel={reserveLabel}
        filesLimit={filesLimit}
        dropzoneText={dropzoneText}
        advertizeText={advertizeText}
        title={title}
        checkboxNeeded={checkboxNeeded}
        classes={classes}
        formOption={formOption}
        FormComponent={FormComponent}
        editionMode={!!editIcon}
      />
    </>
  )
}

UploaderBtn.propTypes = {
  acceptedFiles: PropTypes.arrayOf(PropTypes.string),
  checkboxNeeded: PropTypes.bool,
  editIcon: PropTypes.bool,
  label: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  reserveLabel: PropTypes.string,
  filesLimit: PropTypes.number,
  dropzoneText: PropTypes.string,
  advertizeText: PropTypes.string,
  onSuccess: PropTypes.func.isRequired,
  onValidation: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    buttonSecondary: PropTypes.string.isRequired,
    multiselect: PropTypes.string.isRequired,
    editIcon: PropTypes.string.isRequired,
  }).isRequired,
  formOption: PropTypes.shape({}),
  FormComponent: PropTypes.func,
  disabled: PropTypes.bool,
  variant: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string,
}

UploaderBtn.defaultProps = {
  acceptedFiles: ['application/pdf'],
  checkboxNeeded: false,
  editIcon: false,
  FormComponent: null,
  filesLimit: 1,
  formOption: {},
  dropzoneText: '',
  advertizeText: '',
  reserveLabel: null,
  className: null,
  disabled: false,
  variant: '',
  color: '',
}

export default compose(withStyles(styles))(UploaderBtn)
