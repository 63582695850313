import React, { useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { dateFormatter } from 'helpers/date'
import {
  Button,
  Grid,
  withStyles,
  Dialog,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core'
import {
  COSTING_STATUS_VALIDATED,
  COSTING_STATUS_REFUSED,
  COSTING_STATUS_LABELS,
  COSTING_STATE_REFUSE,
  COSTING_STATE_VALIDATE,
  COSTING_STATUS_PENDING_VALIDATION,
} from 'constants/Jobs'
import ConfirmDialog from 'components/shared/ConfirmDialog/ConfirmDialog'
import InlineTextField from 'components/shared/InlineTextField/InlineTextField'
import UploaderBtn from 'components/shared/Uploader/UploaderBtn'
import { hasPermission } from 'services/permissions'
import { PERM_JOB_MANAGE_COSTING } from 'constants/permissions'
import { Alert } from '@material-ui/lab'
import AmountCosting from './AmountCosting'

import styles from './JobCostingStyles'

const JobCosting = ({
  dispatchGetCostingInfo,
  dispatchRequestCostingValidation,
  dispatchOpenFile,
  jobIri,
  currentCosting,
  translate,
  onSuccessFileLoad,
  onValidationDocumentUploaded,
  classes,
}) => {
  const [isUploadModalOpen, setUploadModalOpen] = useState(false)
  const [isViewCostingModalOpen, setIsViewCostingModalOpen] = useState(false)
  const [confirmDialogOpened, setDialogOpened] = useState(false)
  const [confirmDialogState, setDialogState] = useState(null)

  const displayConfirmDialog = useCallback(
    (costingState, costingIri) => () => {
      setDialogState({ costingState, costingIri })
      setDialogOpened(true)
    },
    [],
  )
  const handleResponse = useCallback(
    ({ currentTarget: { value } }) => {
      if (value === 'true') {
        dispatchRequestCostingValidation(confirmDialogState, jobIri)
      }
      setDialogOpened(!confirmDialogOpened)
    },
    [
      confirmDialogOpened,
      confirmDialogState,
      dispatchRequestCostingValidation,
      jobIri,
    ],
  )

  const handleOpenViewCostingModal = useCallback(() => {
    setIsViewCostingModalOpen(true)
  }, [])

  const handleCloseViewCostingModal = useCallback(() => {
    setIsViewCostingModalOpen(false)
  }, [])

  const handleCloseUploadModal = useCallback(() => {
    setUploadModalOpen(false)
  }, [])

  useEffect(() => {
    dispatchGetCostingInfo(jobIri)
  }, [dispatchGetCostingInfo, jobIri])

  useEffect(() => {
    if (currentCosting) {
      handleCloseUploadModal()
    }
  }, [currentCosting, handleCloseUploadModal])

  const hasCostingPermission = hasPermission(PERM_JOB_MANAGE_COSTING)

  const renderUploaderBtn = () => (
    <UploaderBtn
      acceptedFiles={['application/pdf', 'image/png', 'image/jpeg']}
      className={classes.button}
      variant="contained"
      color="secondary"
      title={translate('job.job_costing.add_costing')}
      label={translate('job.job_costing.add_costing')}
      disabled={!hasCostingPermission}
      onSuccess={onSuccessFileLoad}
      onValidation={onValidationDocumentUploaded}
      dropzoneText={translate('job.job_costing.dropzoneText')}
      advertizeText={translate('job.job_costing.advertizeText')}
      FormComponent={AmountCosting}
      filesLimit={10}
    />
  )

  if (!currentCosting) {
    return (
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        spacing={5}
      >
        <Grid item xs={6}>
          {translate('job.job_costing.descriptive_text')}
        </Grid>
        <Grid item xs={6}>
          {renderUploaderBtn()}
        </Grid>
      </Grid>
    )
  }

  const isRefused = currentCosting?.status === COSTING_STATUS_REFUSED
  const isValidated = currentCosting?.status === COSTING_STATUS_VALIDATED

  return (
    <div>
      {confirmDialogOpened && (
        <ConfirmDialog
          title={translate(
            `job.job_costing.modal.title.${confirmDialogState.costingState}`,
          )}
          onClose={handleResponse}
        >
          {translate(
            `job.job_costing.modal.description.${confirmDialogState.costingState}`,
          )}
        </ConfirmDialog>
      )}
      <div>
        <InlineTextField
          label={translate('job.job_costing.costing_date')}
          field={dateFormatter(
            currentCosting.createdAt,
            translate('app.date_format.short'),
          )}
          isBold
        />
        <InlineTextField
          label={translate('job.job_costing.costing_amount')}
          field={`${currentCosting.amount}€ HT`}
          isBold
          Element="span"
        />
        <InlineTextField
          label={translate('job.job_costing.costing_status_label')}
          field={COSTING_STATUS_LABELS[currentCosting.status]}
          isBold
          Element="span"
        />
        {currentCosting.updatedAt && isValidated && (
          <InlineTextField
            label={translate('job.job_costing.validation_date')}
            field={dateFormatter(
              currentCosting.updatedAt,
              translate('app.date_format.short'),
            )}
            isBold
            Element="span"
          />
        )}
        <Grid container className={classes.container}>
          {currentCosting.status !== COSTING_STATUS_VALIDATED && (
            <Grid item>
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <Button
                    variant="contained"
                    color="secondary"
                    fullWidth
                    disabled={isRefused || !hasCostingPermission}
                    className={classes.button}
                    onClick={displayConfirmDialog(
                      COSTING_STATE_VALIDATE,
                      currentCosting['@id'],
                    )}
                  >
                    {translate('job.job_costing.validate')}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="secondary"
                    fullWidth
                    disabled={isRefused || !hasCostingPermission}
                    className={classes.button}
                    onClick={displayConfirmDialog(
                      COSTING_STATE_REFUSE,
                      currentCosting['@id'],
                    )}
                  >
                    {translate('job.job_costing.refuse')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid item>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.button}
                  style={!isValidated ? { marginLeft: '10px' } : {}}
                  onClick={handleOpenViewCostingModal}
                >
                  {translate('job.job_costing.view_costing')}
                </Button>
              </Grid>
              {isRefused && (
                <Grid item style={{ marginLeft: '10px' }}>
                  {renderUploaderBtn()}
                </Grid>
              )}
            </Grid>
          </Grid>
          <Dialog
            open={isViewCostingModalOpen}
            onClose={handleCloseViewCostingModal}
          >
            <DialogContent>
              <List>
                {(currentCosting?.document?.files || []).map(doc => (
                  <ListItem
                    button
                    onClick={() =>
                      dispatchOpenFile({
                        fileId: doc.id,
                        fileType: 'application/pdf',
                      })
                    }
                    key={doc.id}
                  >
                    <ListItemText
                      primary={doc.name}
                      classes={{ primary: classes.listItemText }}
                    />
                  </ListItem>
                ))}
              </List>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseViewCostingModal} color="primary">
                {translate('app.action.cancel')}
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog open={isUploadModalOpen} onClose={handleCloseUploadModal}>
            <DialogActions>
              <Button onClick={handleCloseUploadModal} color="primary">
                {translate('app.action.cancel')}
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
        {hasCostingPermission &&
          currentCosting.status === COSTING_STATUS_PENDING_VALIDATION && (
            <Grid container>
              <Alert severity="warning" className={classes.warningAlert}>
                {translate('resources.permission.manager.required')}
              </Alert>
            </Grid>
          )}
      </div>
    </div>
  )
}

JobCosting.propTypes = {
  dispatchGetCostingInfo: PropTypes.func.isRequired,
  dispatchRequestCostingValidation: PropTypes.func.isRequired,
  dispatchOpenFile: PropTypes.func.isRequired,
  jobIri: PropTypes.string.isRequired,
  currentCosting: PropTypes.shape({
    '@id': PropTypes.string,
    amount: PropTypes.string,
    createdAt: PropTypes.string,
    updatedAt: PropTypes.string,
    status: PropTypes.string,
    document: PropTypes.shape({
      files: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
        }),
      ),
    }),
  }),
  translate: PropTypes.func.isRequired,
  onSuccessFileLoad: PropTypes.func.isRequired,
  onValidationDocumentUploaded: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    button: PropTypes.string,
    container: PropTypes.string,
    listItemText: PropTypes.string,
    warningAlert: PropTypes.string,
  }).isRequired,
}

JobCosting.defaultProps = {
  currentCosting: null,
}

export default withStyles(styles)(JobCosting)
