export default () => ({
  container: {
    marginTop: '30px',
    width: '100%',
  },
  button: {
    textTransform: 'none',
    padding: '10px 50px',
  },
  listItemText: {
    color: 'black !important',
    textAlign: 'left',
  },
  warningAlert: {
    marginTop: '24px',
  },
})
