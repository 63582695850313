import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import {
  acceptedProIdSelector,
  invoicesSelector,
} from 'store/jobs/jobSelectors'
import { paymentBlockedSelector } from 'store/firms/firmSelectors'
import {
  doDisplayPurchaseInvoiceCreationDialog,
  getJobInvoicesList,
  setDisplayedInvoice,
} from 'store/invoices/invoiceActions'
import {
  displayedInvoiceSelector,
  selectCreateTravelCompensationPurchaseInvoiceDialogDisplayed,
  selectCreatePurchaseInvoiceDialogDisplayed,
} from 'store/invoices/invoiceSelectors'
import i18n from 'providers/i18n/I18nProvider'
import { formatIriToId } from 'helpers/utils/common'
import { CircularProgress } from '@material-ui/core'

import JobDetailsInvoices from './JobDetailsInvoices'

const JobDetailsInvoicesContainer = ({
  invoices,
  translate,
  dispatchSetDisplayedInvoice,
  dispatchResetDisplayedInvoice,
  displayedInvoice,
  getInvoices,
  createTravelCompensationPurchaseInvoiceDialogDisplayed,
  displayPurchaseInvoiceCreationDialog,
  createPurchaseInvoiceDialogDisplayed,
  proId,
  paymentBlocked,
}) => {
  useEffect(() => {
    if (!invoices.listRequested && !invoices.listRequesting) {
      getInvoices()
    }
  }, [getInvoices, invoices.listRequested, invoices.listRequesting])

  if (invoices.listRequesting) {
    return <CircularProgress size={20} />
  }

  return (
    <JobDetailsInvoices
      translate={translate}
      invoices={invoices}
      dispatchSetDisplayedInvoice={dispatchSetDisplayedInvoice}
      dispatchResetDisplayedInvoice={dispatchResetDisplayedInvoice}
      displayedInvoice={displayedInvoice}
      createTravelCompensationPurchaseInvoiceDialogDisplayed={
        createTravelCompensationPurchaseInvoiceDialogDisplayed
      }
      displayPurchaseInvoiceCreationDialog={
        displayPurchaseInvoiceCreationDialog
      }
      createPurchaseInvoiceDialogDisplayed={
        createPurchaseInvoiceDialogDisplayed
      }
      proId={proId}
      paymentBlocked={paymentBlocked}
    />
  )
}

JobDetailsInvoicesContainer.propTypes = {
  translate: PropTypes.func.isRequired,
  getInvoices: PropTypes.func.isRequired,
  invoices: PropTypes.shape({
    list: PropTypes.arrayOf(PropTypes.shape({})),
    totalItems: PropTypes.number,
    loading: PropTypes.bool,
    listRequesting: PropTypes.bool,
    listRequested: PropTypes.bool,
  }),
  dispatchSetDisplayedInvoice: PropTypes.func.isRequired,
  dispatchResetDisplayedInvoice: PropTypes.func.isRequired,
  displayedInvoice: PropTypes.number,
  createTravelCompensationPurchaseInvoiceDialogDisplayed:
    PropTypes.bool.isRequired,
  displayPurchaseInvoiceCreationDialog: PropTypes.func.isRequired,
  createPurchaseInvoiceDialogDisplayed: PropTypes.bool.isRequired,
  proId: PropTypes.string.isRequired,
  paymentBlocked: PropTypes.bool,
}

JobDetailsInvoicesContainer.defaultProps = {
  invoices: {
    list: [],
    totalItems: 0,
    loading: false,
    listRequesting: false,
    listRequested: false,
  },
  displayedInvoice: null,
  paymentBlocked: false,
}

const mapStateToProps = state => {
  const proIri = acceptedProIdSelector(state)
  const proId = proIri ? formatIriToId(proIri) : null

  return {
    invoices: invoicesSelector(state),
    displayedInvoice: displayedInvoiceSelector(state),
    createTravelCompensationPurchaseInvoiceDialogDisplayed: selectCreateTravelCompensationPurchaseInvoiceDialogDisplayed(
      state,
    ),
    createPurchaseInvoiceDialogDisplayed: selectCreatePurchaseInvoiceDialogDisplayed(
      state,
    ),
    proId,
    paymentBlocked: paymentBlockedSelector(state),
  }
}

const mapDispatchToProps = dispatch => ({
  getInvoices: () => dispatch(getJobInvoicesList.request()),
  dispatchSetDisplayedInvoice: invoiceId =>
    dispatch(setDisplayedInvoice(invoiceId)),
  dispatchResetDisplayedInvoice: () => dispatch(setDisplayedInvoice(null)),
  displayPurchaseInvoiceCreationDialog: () =>
    dispatch(
      doDisplayPurchaseInvoiceCreationDialog({
        payload: { displayed: true },
      }),
    ),
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  i18n,
)(JobDetailsInvoicesContainer)
