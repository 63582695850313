import React from 'react'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import i18n from 'providers/i18n/I18nProvider'

import { CardHeader, Typography, withStyles } from '@material-ui/core'
import Icon from 'components/shared/Icon/Icon'
import styles from './FirmDetailsProDirStatusStyles'

const getTitle = hasPaymentBlocked => {
  if (hasPaymentBlocked) {
    return 'statuspaymentsBlocked'
  }
  return 'statuspaymentsNotBlocked'
}

const FirmDetailsProDirPaymentBlockStatus = ({
  translate,
  classes,
  hasPaymentBlocked,
}) => {
  const iconName = hasPaymentBlocked ? 'disabled' : 'activate'
  const fieldTitle = getTitle(hasPaymentBlocked)
  const title = translate(`resources.firms.fields.${fieldTitle}`)

  return (
    <>
      <Typography className={classes.label}>
        {translate('resources.firms.fields.statusPaymentBlock')}:
      </Typography>
      <CardHeader
        avatar={<Icon className={classes.icon} icon={`${iconName}.svg`} />}
        title={title}
        classes={{
          title: hasPaymentBlocked
            ? classes.proDirectoryisDesactivate
            : classes.proDirectoryisActivate,
        }}
      />
    </>
  )
}

FirmDetailsProDirPaymentBlockStatus.propTypes = {
  translate: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    icon: PropTypes.string,
    label: PropTypes.string,
    proDirectoryisActivate: PropTypes.string,
    proDirectoryisDesactivate: PropTypes.string,
  }).isRequired,
  hasPaymentBlocked: PropTypes.bool,
}

FirmDetailsProDirPaymentBlockStatus.defaultProps = {
  hasPaymentBlocked: false,
}

export default compose(
  i18n,
  withStyles(styles),
)(FirmDetailsProDirPaymentBlockStatus)
