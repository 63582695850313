import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { reset } from 'redux-form'
import { storeList } from 'store/stores/storeActions'
import { PackageActions } from 'store/products/packageActions'
import { PackageSelector } from 'store/products/packageSelector'
import { storesListSelector } from 'store/stores/storeSelectors'
import {
  dbDepsZoneSelectedSelector,
  departmentsListByZoneSelector,
} from 'store/departments/departmentSelectors'
import { getDepartmentsList } from 'store/departments/departmentActions'
import { resetJobsList, resetJobsDashboardParams } from 'store/jobs/jobActions'
import { getPartners } from 'store/partners/partnersActions'
import {
  AUTO_AFTER_SALE_TYPE,
  JOBS_STATUS,
  MANUAL_DIAGNOSTIC_AFTER_SALE_TYPE,
  MANUAL_INITIAL_AFTER_SALE_TYPE,
  MANUAL_RESOLUTION_AFTER_SALE_TYPE,
  PK_TYPE,
  SEARCH,
} from 'constants/Jobs'

import {
  dashboardSearchParamsSelector,
  searchParamsSelector,
} from 'store/jobs/jobSelectors'
import { getLSProLegacyId } from 'store/localStorage/localStorageActions'
import {
  incidentStatusesForSelectSelector,
  incidentWaitingForListForSelectSelector,
} from 'store/incidents/incidentSelectors'
import {
  fetchIncidentStatuses,
  fetchIncidentWaitingForList,
} from 'store/incidents/incidentActions'
import { partnerListSelector } from 'store/partners/partnersSelectors'
import { getReserveTypesReq } from 'store/reserves/reservesActions'
import { reserveTypesListSelector } from 'store/reserves/reservesSelectors'
import { isFeatureFlagEnabledSelector } from 'store/Application/ApplicationSelectors'
import { CLIENT_RESERVES } from 'constants/features'
import JobsSearchFilters from './JobsSearchFilters'

const JobsSearchFiltersContainer = ({
  handleSearchChange,
  handleReset,
  handleExport,
  getStoreList,
  getPartnersList,
  getDepartments,
  getReserveTypes,
  getPackageList,
  storeCodes,
  departments,
  reserveTypes,
  packageList,
  partnerList,
  dashboardSearchParams,
  searchParams,
  getProLegacyId,
  incidentStatuses,
  incidentWaitingForList,
  dispatchFetchIncidentStatuses,
  dispatchFetchIncidentWaitingForList,
  dbsPostCodes,
  isClientReservesEnabled,
}) => {
  useEffect(() => {
    getDepartments()
    getPackageList()
    getProLegacyId()
    dispatchFetchIncidentStatuses()
    dispatchFetchIncidentWaitingForList()
    getPartnersList()
    if (isClientReservesEnabled) {
      getReserveTypes()
    }
  }, [
    getDepartments,
    getPackageList,
    getProLegacyId,
    dispatchFetchIncidentStatuses,
    dispatchFetchIncidentWaitingForList,
    getPartnersList,
    getReserveTypes,
    isClientReservesEnabled,
  ])

  const reserveKey = isClientReservesEnabled
    ? 'reserveTypes'
    : 'withReceiptWithReserves'

  const searchParameters = {
    ...searchParams,
    jobStatus:
      searchParams.jobStatus !== SEARCH
        ? searchParams.jobStatus
        : dashboardSearchParams.jobStatus,
    pro: searchParams.pro || dashboardSearchParams.pro || '',
    [reserveKey]: searchParams[reserveKey] || dashboardSearchParams[reserveKey],
    incidentStatus:
      searchParams.incidentStatus || dashboardSearchParams.incidentStatus,
    incidentWaitingFor:
      searchParams.incidentWaitingFor ||
      dashboardSearchParams.incidentWaitingFor,
    postCodes: dbsPostCodes,
    orderBy: searchParams.orderBy,
    interventionType: searchParams.interventionType,
  }

  const interventionTypes = [
    { label: 'Intervention initiale', code: PK_TYPE },
    { label: 'SAV automatique', code: AUTO_AFTER_SALE_TYPE },
    {
      label: 'SAV avec Artisan initial',
      code: MANUAL_INITIAL_AFTER_SALE_TYPE,
    },
    { label: 'Diagnostic', code: MANUAL_DIAGNOSTIC_AFTER_SALE_TYPE },
    { label: 'SAV résolution', code: MANUAL_RESOLUTION_AFTER_SALE_TYPE },
  ]

  return (
    <JobsSearchFilters
      handleSearchChange={handleSearchChange}
      handleReset={handleReset}
      handleExport={handleExport}
      initialValues={searchParameters}
      storeCodes={storeCodes}
      departments={departments}
      reserveTypes={reserveTypes}
      interventionTypes={interventionTypes}
      packageList={packageList}
      partnerList={partnerList}
      getStoreList={getStoreList}
      orderStatus={JOBS_STATUS}
      incidentStatuses={incidentStatuses}
      incidentWaitingForList={incidentWaitingForList}
      dashboardSearchParams={dashboardSearchParams}
      isClientReservesEnabled={isClientReservesEnabled}
    />
  )
}

JobsSearchFiltersContainer.propTypes = {
  handleSearchChange: PropTypes.func.isRequired,
  getStoreList: PropTypes.func.isRequired,
  getPartnersList: PropTypes.func.isRequired,
  getDepartments: PropTypes.func.isRequired,
  getReserveTypes: PropTypes.func.isRequired,
  getPackageList: PropTypes.func.isRequired,
  getProLegacyId: PropTypes.func.isRequired,
  handleReset: PropTypes.func.isRequired,
  handleExport: PropTypes.func.isRequired,
  storeCodes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  partnerList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  departments: PropTypes.shape({}).isRequired,
  reserveTypes: PropTypes.shape({}).isRequired,
  packageList: PropTypes.shape({}).isRequired,
  dashboardSearchParams: PropTypes.shape({
    jobStatus: PropTypes.string,
    reserveType: PropTypes.string,
    forceSubmit: PropTypes.bool,
    incidentStatus: PropTypes.string,
    incidentWaitingFor: PropTypes.string,
    pro: PropTypes.string,
    reserveTypes: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  searchParams: PropTypes.shape({
    jobStatus: PropTypes.string,
    reserveType: PropTypes.string,
    interventionType: PropTypes.arrayOf(PropTypes.string),
    withReceiptWithReserves: PropTypes.bool,
    incidentStatus: PropTypes.string,
    incidentWaitingFor: PropTypes.string,
    postCodes: PropTypes.arrayOf(PropTypes.string),
    reserveTypes: PropTypes.arrayOf(PropTypes.string),
    pro: PropTypes.string,
    orderBy: PropTypes.string,
  }).isRequired,
  incidentStatuses: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  incidentWaitingForList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  dispatchFetchIncidentStatuses: PropTypes.func.isRequired,
  dispatchFetchIncidentWaitingForList: PropTypes.func.isRequired,
  dbsPostCodes: PropTypes.arrayOf(PropTypes.string).isRequired,
  isClientReservesEnabled: PropTypes.string.isRequired,
}

const mapStateToProps = state => ({
  storeCodes: storesListSelector(state),
  partnerList: partnerListSelector(state),
  departments: departmentsListByZoneSelector(state),
  reserveTypes: reserveTypesListSelector(state),
  packageList: PackageSelector.typeListIndexedByCodeSelector()(state),
  dashboardSearchParams: dashboardSearchParamsSelector(state),
  searchParams: searchParamsSelector(state),
  incidentStatuses: incidentStatusesForSelectSelector(state),
  incidentWaitingForList: incidentWaitingForListForSelectSelector(state),
  dbsPostCodes: dbDepsZoneSelectedSelector(state),
  isClientReservesEnabled: isFeatureFlagEnabledSelector(state, CLIENT_RESERVES),
})

const mapDispatchToProps = dispatch => ({
  getStoreList: partner => dispatch(storeList.request({ partner })),
  getDepartments: () => dispatch(getDepartmentsList.request()),
  getReserveTypes: () => dispatch(getReserveTypesReq.request()),
  getPackageList: () => dispatch(PackageActions.getTypeList().request()),
  getPartnersList: () => dispatch(getPartners.request()),
  dispatchFetchIncidentStatuses: () =>
    dispatch(fetchIncidentStatuses.request()),
  dispatchFetchIncidentWaitingForList: () =>
    dispatch(fetchIncidentWaitingForList.request()),
  handleReset: formName => {
    dispatch(reset(formName))
    dispatch(resetJobsList())
    dispatch(resetJobsDashboardParams())
  },
  getProLegacyId: () => dispatch(getLSProLegacyId()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(JobsSearchFiltersContainer)
