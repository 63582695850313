import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { reduxForm, Field } from 'redux-form'
import intersection from 'lodash/intersection'
import get from 'lodash/get'
import uuidv1 from 'uuid/v1'
import RenderCheckbox from 'components/shared/Form/RenderCheckbox'
import SimpleDialog from 'components/shared/SimpleDialog/SimpleDialog'
import i18n from 'providers/i18n/I18nProvider'
import {
  withStyles,
  Grid,
  FormControl,
  FormGroup,
  FormControlLabel,
  Paper,
  Typography,
} from '@material-ui/core'
import { EDIT_FIRM_DETAILS_PACKAGES_FORM } from 'constants/forms'
import { showNotification } from 'store/Application/ApplicationActions'
import translate from 'providers/i18n/translateService'
import { ERROR } from 'constants/variant'
import { styles, VerticalTabs, MyTab } from './FirmDetailsPackageStyles'

export const validate = (values, props) => {
  const { isFirmValidatedForPackage } = props

  const errors = {}

  if (
    isFirmValidatedForPackage &&
    (Object.keys(values).length === 0 || !Object.values(values).includes(true))
  ) {
    errors.checkBox = translate('resources.firms.validation.required_package')
  }

  return errors
}

const FirmDetailsPackage = ({
  classes,
  firmLabelList,
  isFirmValidatedForPackage,
  forceValidation,
  packageDetails,
  handleSubmit,
  translate,
  selectedDepartments,
  packageList,
}) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const [historySelectedPackage, setHistorySelectedPackage] = useState([])
  const [openDialog, setOpenDialog] = useState(false)

  const initialState = () => {
    setHistorySelectedPackage(firmLabelList)
  }

  useEffect(() => {
    initialState(firmLabelList)
  }, [firmLabelList])

  useEffect(() => {
    if (isFirmValidatedForPackage) {
      forceValidation()
    }
  }, [isFirmValidatedForPackage, forceValidation])

  const handleChange = (_, index) => setActiveIndex(index)

  const handleCloseDialog = () => setOpenDialog(false)

  const selectPackage = (e, name, code) => {
    const { checked } = e.target
    const departmentCodes = get(packageList, `${code}.department_codes`, [])
    let dialog = false

    if (
      checked === true &&
      departmentCodes.length > 0 &&
      selectedDepartments.length > 0 &&
      intersection(selectedDepartments, departmentCodes).length === 0
    ) {
      dialog = true
    }

    setHistorySelectedPackage(prev => ({
      ...prev,
      [name]: checked,
    }))
    setOpenDialog(dialog)
  }

  const checkedHistoryPackage = Object.keys(historySelectedPackage).filter(
    pk => historySelectedPackage[pk],
  )

  return (
    <form onSubmit={handleSubmit} className={classes.form}>
      <Grid container justifyContent="space-between">
        <Grid item xs={12} sm={4}>
          <VerticalTabs value={activeIndex} onChange={handleChange}>
            {Object.keys(packageDetails).map(tab => (
              <MyTab label={tab} key={uuidv1()} />
            ))}
          </VerticalTabs>
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl component="fieldset">
            <FormGroup>
              {/* This field is used to force this form validation when packageStatus changed */}
              <Field
                name="checkBox"
                isDisabled
                customStyle={{ display: 'none' }}
                component={RenderCheckbox}
              />
              {Object.keys(packageDetails).map(
                (value, key) =>
                  activeIndex === key &&
                  packageDetails[value].map(item => (
                    <FormControlLabel
                      key={item.code}
                      onChange={e => selectPackage(e, item.name, item.code)}
                      control={
                        <Field
                          name={item.code}
                          label={item.name}
                          component={RenderCheckbox}
                        />
                      }
                    />
                  )),
              )}
            </FormGroup>
          </FormControl>
          {openDialog && (
            <SimpleDialog
              title={translate('resources.firms.message.dialog_package_title')}
              onClose={handleCloseDialog}
            >
              <span>
                {translate('resources.firms.message.dialog_package_message')}
              </span>
            </SimpleDialog>
          )}
        </Grid>
        <Grid item xs={12} sm={4}>
          {checkedHistoryPackage.length > 0 && (
            <Paper className={classes.root} elevation={1}>
              {checkedHistoryPackage.map(value => (
                <Typography
                  display="block"
                  key={uuidv1()}
                  variant="button"
                  gutterBottom
                >
                  {value}
                </Typography>
              ))}
            </Paper>
          )}
        </Grid>
      </Grid>
    </form>
  )
}

FirmDetailsPackage.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string.isRequired,
    form: PropTypes.string.isRequired,
  }).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  packageDetails: PropTypes.shape({}).isRequired,
  firmLabelList: PropTypes.shape({}).isRequired,
  packageList: PropTypes.shape({}).isRequired,
  selectedDepartments: PropTypes.arrayOf(PropTypes.string).isRequired,
  isFirmValidatedForPackage: PropTypes.bool.isRequired,
  translate: PropTypes.func.isRequired,
  forceValidation: PropTypes.func.isRequired,
}

export default compose(
  reduxForm({
    form: EDIT_FIRM_DETAILS_PACKAGES_FORM,
    enableReinitialize: true,
    validate,
    onSubmitFail: (errors, dispatch) => {
      dispatch(
        showNotification({
          payload: {
            message: translate('resources.firms.validation.onSubmitFail'),
            messageType: ERROR,
          },
        }),
      )
    },
  }),
  i18n,
  withStyles(styles),
)(FirmDetailsPackage)
