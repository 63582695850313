import {
  GET_COUNT_JOBS_BY_STATUSES,
  GET_JOBS_LIST,
  GET_SINGLE_JOB,
  REFRESH_JOB,
  GET_MATCHING_PROS,
  GET_MATCHING_PROS_LIST,
  GET_MATCHING_PROS_LIST_RANKING,
  UPDATE_JOB_REQ,
  GET_JOB_EVENTS_FOR_JOB_REQ,
  RELIABLE_PRO_LIST,
  JOB_ACCEPT_TRANSITION_REQ,
  JOB_DECLINE_TRANSITION_REQ,
  JOB_RESCHEDULING_TRANSITION_REQ,
  TO_PENDING_PAYMENT_TRANSITION_REQ,
  GET_RECEIPT_JOB,
  GET_CERFA_JOB,
  GET_INVOICE_JOB,
  RESET_JOBS_LIST,
  JOB_REMATCHING_TRANSITION_REQ,
  SET_SELECTED_TABBED_JOBS_INDEX,
  GET_RECEIPT_SIGNATURE,
  GET_CERFA_SIGNATURE,
  RESET_JOBS_DASHBOARD_PARAMS,
  UPDATE_SEARCH_JOB_PARAMS,
  PROCESS_JOB_START_TRANSITION_REQ,
  TO_PENDING_CANCELATION_TRANSITION_REQ,
  GET_MATCHINGS,
  POST_CLIENT_DATA_REQ,
  JOB_PENDING_NEW_DATE_TRANSITION_REQ,
  TO_CANCEL_TRANSITION_REQ,
  TO_DONE_TRANSITION_REQ,
  GET_JOB_DIAGNOSTIC,
  GET_COSTING_INFO_REQ,
} from './jobActions'
import { FETCH_INCIDENT } from '../incidents/incidentActions'
import { GET_JOB_INVOICES_LIST } from '../invoices/invoiceActions'

import { initialState } from './jobSelectors'
import { formatRankingRating } from '../../helpers/utils/firm/firm'
import {
  INVOICE_CONTEST_TRANSITION_REQ,
  INVOICE_CANCEL_CONTEST_TRANSITION_REQ,
  INVOICE_REEDITION_TRANSITION_REQ,
  INVOICE_PAY_TRANSITION_REQ,
} from '../invoices/transitions/invoiceTransitionActions'

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_JOBS_LIST.REQUEST:
      return {
        ...state,
        searchParams: {
          ...state.searchParams,
          jobStatus: action.jobStatus,
          page: action.page,
          rowsPerPage: action.rowsPerPage,
          orderBy: action.orderBy || null,
          reserveType: action.reserveType,
          interventionType: action.interventionType,
          withReceiptWithReserves: action.withReceiptWithReserves,
          incidentStatus: action.incidentStatus,
          incidentWaitingFor: action.incidentWaitingFor,
          sort: action.sort || initialState.searchParams.sort,
          orderNumber:
            action.orderNumber || initialState.searchParams.orderNumber,
          job: action.job || initialState.searchParams.job,
          pro: action.pro || initialState.searchParams.pro,
          storeCode: action.storeCode || initialState.searchParams.storeCode,
          partner: action.partner || initialState.searchParams.partner,
          phoneNumber:
            action.phoneNumber || initialState.searchParams.phoneNumber,
          postCode: action.postCode || initialState.searchParams.postCode,
          postCodes: action.postCodes || initialState.searchParams.postCodes,
          minDateHo: action.minDateHo || initialState.searchParams.minDateHo,
          minDatePro: action.minDatePro || initialState.searchParams.minDatePro,
          lastName: action.lastName || initialState.searchParams.lastName,
          packageType:
            action.packageType || initialState.searchParams.packageType,
        },
      }
    case GET_JOBS_LIST.SUCCESS:
      return {
        ...state,
        list: action.payload['hydra:member'],
        total: action.payload['hydra:totalItems'],
        dashboardSearchParams: {
          ...state.dashboardSearchParams,
          forceSubmit: false,
        },
      }
    case RESET_JOBS_LIST:
      return {
        ...state,
        list: [],
      }
    case RESET_JOBS_DASHBOARD_PARAMS:
      return {
        ...state,
        dashboardSearchParams: initialState.dashboardSearchParams,
        searchParams: initialState.searchParams,
      }
    case SET_SELECTED_TABBED_JOBS_INDEX:
      return {
        ...state,
        selectedTabbedJobsIndex: action.selectedTabbedJobsIndex,
      }
    case GET_SINGLE_JOB.REQUEST:
      return {
        ...state,
        currentJob: {
          jobId: action.jobId,
        },
      }
    case FETCH_INCIDENT.REQUEST:
      return {
        ...state,
        currentJob: {
          ...state.currentJob,
          incidents: {
            ...state.currentJob.incidents,
            loading: true,
            incidentListRequested: false,
            incidentListRequesting: true,
          },
        },
      }
    case FETCH_INCIDENT.SUCCESS:
      return {
        ...state,
        currentJob: {
          ...state.currentJob,
          incidents: {
            list: action.payload['hydra:member'],
            totalItems: action.payload['hydra:totalItems'],
            loading: false,
            incidentListRequested: true,
            incidentListRequesting: false,
          },
        },
      }
    case FETCH_INCIDENT.FAILURE:
      return {
        ...state,
        currentJob: {
          ...state.currentJob,
          incidents: {
            ...initialState.currentJob.incidents,
          },
        },
      }
    case GET_JOB_INVOICES_LIST.REQUEST:
      return {
        ...state,
        currentJob: {
          ...state.currentJob,
          invoices: {
            ...state.currentJob.invoices,
            loading: true,
            listRequested: false,
            listRequesting: true,
          },
        },
      }
    case GET_JOB_INVOICES_LIST.SUCCESS:
      return {
        ...state,
        currentJob: {
          ...state.currentJob,
          invoices: {
            list: action.payload['hydra:member'],
            totalItems: action.payload['hydra:totalItems'],
            loading: false,
            listRequested: true,
            listRequesting: false,
          },
        },
      }
    case GET_JOB_INVOICES_LIST.FAILURE:
      return {
        ...state,
        currentJob: {
          ...state.currentJob,
          invoices: {
            ...initialState.currentJob.invoices,
          },
        },
      }
    case UPDATE_JOB_REQ.SUCCESS:
    case GET_SINGLE_JOB.SUCCESS:
    case REFRESH_JOB.SUCCESS:
      return {
        ...state,
        currentJob: {
          ...state.currentJob,
          acceptedPro: action.payload.acceptedPro,
          acceptedJobPros: action.payload.acceptedJobPros,
          acceptedProExpiredAtCertificate:
            action.payload.acceptedProExpiredAtCertificate,
          acceptedTimeslot: action.payload.acceptedTimeslot,
          cerfaSignatureStatus: action.payload.cerfaSignatureStatus,
          cerfa15497FileId: action.payload.cerfa15497FileId,
          cerfa15498FileId: action.payload.cerfa15498FileId,
          cerfa15497UploadDate: action.payload.cerfa15497UploadDate,
          cerfa15498UploadDate: action.payload.cerfa15498UploadDate,
          products: action.payload.products,
          status: action.payload.status,
          substatus: action.payload.substatus,
          timeslots: action.payload.timeslots,
          jobPictures: action.payload.pictures,
          receiptSignatureStatus: action.payload.receiptSignatureStatus,
          receiptSignatureId: action.payload.receiptSignatureId,
          signedReceipt: action.payload.signedReceipt,
          receiptSignatureRefusalMember:
            action.payload.receiptSignatureRefusalMember,
          receiptSignatureRefusalReason:
            action.payload.receiptSignatureRefusalReason,
          invoiceStatus: action.payload.invoiceStatus,
          invoiceIri: action.payload.invoiceIri,
          invoiceId: action.payload.invoiceId,
          invoiceDispute: action.payload.invoiceDispute,
          receiptFileId: action.payload.receiptFileId,
          reserves: action.payload.reserves,
          worksite: action.payload.worksite,
          minDatePro: action.payload.minDatePro,
          minDateHo: action.payload.minDateHo,
          type: action.payload.type,
          lastMatchingId: action.payload.lastMatchingId,
        },
      }
    case GET_RECEIPT_SIGNATURE.SUCCESS:
      return {
        ...state,
        currentJob: {
          ...state.currentJob,
          receiptSignature: action.payload,
        },
      }
    case GET_CERFA_SIGNATURE.SUCCESS:
      return {
        ...state,
        currentJob: {
          ...state.currentJob,
          cerfaSignature: action.payload,
        },
      }
    case GET_MATCHING_PROS.SUCCESS:
      return {
        ...state,
        currentJob: {
          ...state.currentJob,
          matchingPros: action.payload['hydra:member'],
          matchingProsCount: action.payload['hydra:totalItems'],
        },
      }
    case GET_MATCHING_PROS_LIST.SUCCESS:
      return {
        ...state,
        currentJob: {
          ...state.currentJob,
          matchingProsAlgolia: action.payload,
          matchingProsCountAlgolia: action.payload.length,
        },
      }
    case GET_MATCHING_PROS_LIST_RANKING.SUCCESS:
      return {
        ...state,
        currentJob: {
          ...state.currentJob,
          matchingProsAlgoliaRanking: action.payload.map(matchingPro => ({
            ...matchingPro,
            pro: {
              ...matchingPro.pro,
              rankingRating: formatRankingRating(matchingPro.pro.rankingRating),
            },
          })),
          matchingProsCountAlgoliaRanking: action.payload.length,
        },
      }
    case GET_COUNT_JOBS_BY_STATUSES.SUCCESS:
      return {
        ...state,
        jobCounts: action.payload,
      }
    case GET_JOB_EVENTS_FOR_JOB_REQ.REQUEST:
      return {
        ...state,
        jobEventsByJobIdIsLoading: true,
        jobHistoryParams: {
          ...state.jobHistoryParams,
          page: action.searchParams.page,
          perPage: action.searchParams.perPage,
          job: action.searchParams.job,
        },
      }
    case GET_JOB_EVENTS_FOR_JOB_REQ.SUCCESS:
      return {
        ...state,
        jobEventsByJobId: {
          ...state.jobEventsByJobId,
          [action.actionParams.jobId]: action.payload['hydra:member'],
        },
        currentJob: {
          ...state.currentJob,
          jobHistory: {
            events: action.payload['hydra:member'],
            totalItems: action.payload['hydra:totalItems'],
          },
        },
        jobEventsByJobIdIsLoading: false,
      }
    case GET_JOB_EVENTS_FOR_JOB_REQ.FAILURE:
      return {
        ...state,
        jobEventsByJobIdIsLoading: false,
      }
    case RELIABLE_PRO_LIST.SUCCESS:
      return {
        ...state,
        reliableList: action.payload,
        reliableCount: action.actionParams,
      }
    case JOB_ACCEPT_TRANSITION_REQ.REQUEST:
      return {
        ...state,
        jobAcceptTransitionIsLoading: true,
      }
    case JOB_DECLINE_TRANSITION_REQ.REQUEST:
      return {
        ...state,
        jobDeclineTransitionIsLoading: true,
      }
    case JOB_ACCEPT_TRANSITION_REQ.SUCCESS:
    case JOB_ACCEPT_TRANSITION_REQ.FAILURE:
      return {
        ...state,
        jobAcceptTransitionIsLoading: false,
      }
    case JOB_DECLINE_TRANSITION_REQ.SUCCESS:
    case JOB_DECLINE_TRANSITION_REQ.FAILURE:
      return {
        ...state,
        jobDeclineTransitionIsLoading: false,
      }
    case TO_PENDING_PAYMENT_TRANSITION_REQ.REQUEST:
      return {
        ...state,
        jobPaymentTransitionIsLoading: true,
      }
    case TO_PENDING_PAYMENT_TRANSITION_REQ.SUCCESS:
    case TO_PENDING_PAYMENT_TRANSITION_REQ.FAILURE:
      return {
        ...state,
        jobPaymentTransitionIsLoading: false,
      }
    case JOB_RESCHEDULING_TRANSITION_REQ.REQUEST:
      return {
        ...state,
        jobReschedulingTransitionIsLoading: true,
      }
    case JOB_RESCHEDULING_TRANSITION_REQ.SUCCESS:
    case JOB_RESCHEDULING_TRANSITION_REQ.FAILURE:
      return {
        ...state,
        jobReschedulingTransitionIsLoading: false,
      }
    case GET_RECEIPT_JOB.SUCCESS:
      return {
        ...state,
        currentJob: {
          ...state.currentJob,
          receipt: action.payload,
        },
      }
    case GET_CERFA_JOB.SUCCESS:
      return {
        ...state,
        currentJob: {
          ...state.currentJob,
          cerfa: action.payload,
        },
      }
    case GET_INVOICE_JOB.SUCCESS:
      return {
        ...state,
        currentJob: {
          ...state.currentJob,
          invoice: action.payload,
        },
      }
    case JOB_REMATCHING_TRANSITION_REQ.REQUEST:
      return {
        ...state,
        jobRematchingTransitionIsLoading: true,
      }
    case JOB_REMATCHING_TRANSITION_REQ.SUCCESS:
      return {
        ...state,
        currentJob: {
          ...state.currentJob,
          status: action.payload.status,
          substatus: action.payload.substatus,
        },
        jobRematchingTransitionIsLoading: false,
      }
    case JOB_REMATCHING_TRANSITION_REQ.FAILURE:
      return {
        ...state,
        jobRematchingTransitionIsLoading: false,
      }
    case JOB_PENDING_NEW_DATE_TRANSITION_REQ.REQUEST:
      return {
        ...state,
        jobPendingNewDateTransitionIsLoading: true,
      }
    case JOB_PENDING_NEW_DATE_TRANSITION_REQ.SUCCESS:
      return {
        ...state,
        currentJob: {
          ...state.currentJob,
          status: action.payload.status,
          substatus: action.payload.substatus,
        },
        jobPendingNewDateTransitionIsLoading: false,
      }
    case JOB_PENDING_NEW_DATE_TRANSITION_REQ.FAILURE:
      return {
        ...state,
        jobPendingNewDateTransitionIsLoading: false,
      }
    case UPDATE_SEARCH_JOB_PARAMS:
      return {
        ...state,
        dashboardSearchParams: {
          ...action.filters
            .map(filter => ({ [filter.field]: filter.values }))
            .reduce((acc, filter) => ({ ...acc, ...filter }), {}),
          forceSubmit: true,
        },
      }
    case PROCESS_JOB_START_TRANSITION_REQ.REQUEST:
      return {
        ...state,
        processJobStartTransitionIsLoading: true,
      }
    case PROCESS_JOB_START_TRANSITION_REQ.SUCCESS:
      return {
        ...state,
        currentJob: {
          ...state.currentJob,
          status: action.payload.status,
          substatus: action.payload.substatus,
        },
        processJobStartTransitionIsLoading: false,
      }
    case PROCESS_JOB_START_TRANSITION_REQ.FAILURE:
      return {
        ...state,
        processJobStartTransitionIsLoading: false,
      }
    case TO_PENDING_CANCELATION_TRANSITION_REQ.REQUEST:
      return {
        ...state,
        toPendingCancelationTransitionIsLoading: true,
      }
    case TO_PENDING_CANCELATION_TRANSITION_REQ.SUCCESS:
      return {
        ...state,
        currentJob: {
          ...state.currentJob,
          status: action.payload.status,
          substatus: action.payload.substatus,
        },
        toPendingCancelationTransitionIsLoading: false,
      }
    case TO_PENDING_CANCELATION_TRANSITION_REQ.FAILURE:
      return {
        ...state,
        toPendingCancelationTransitionIsLoading: false,
      }
    case TO_CANCEL_TRANSITION_REQ.REQUEST:
      return {
        ...state,
        toCancelTransitionIsLoading: true,
      }
    case TO_CANCEL_TRANSITION_REQ.SUCCESS:
      return {
        ...state,
        currentJob: {
          ...state.currentJob,
          status: action.payload.status,
          substatus: action.payload.substatus,
        },
        toCancelTransitionIsLoading: false,
      }
    case TO_CANCEL_TRANSITION_REQ.FAILURE:
      return {
        ...state,
        toCancelTransitionIsLoading: false,
      }
    case TO_DONE_TRANSITION_REQ.REQUEST:
      return {
        ...state,
        toDoneTransitionIsLoading: true,
      }
    case TO_DONE_TRANSITION_REQ.SUCCESS:
      return {
        ...state,
        currentJob: {
          ...state.currentJob,
          status: action.payload.status,
          substatus: action.payload.substatus,
        },
        toDoneTransitionIsLoading: false,
      }
    case TO_DONE_TRANSITION_REQ.FAILURE:
      return {
        ...state,
        toDoneTransitionIsLoading: false,
      }
    case INVOICE_CONTEST_TRANSITION_REQ.SUCCESS:
    case INVOICE_CANCEL_CONTEST_TRANSITION_REQ.SUCCESS:
    case INVOICE_REEDITION_TRANSITION_REQ.SUCCESS:
    case INVOICE_PAY_TRANSITION_REQ.SUCCESS:
      return {
        ...state,
        currentJob: {
          ...state.currentJob,
          invoiceStatus: action.payload.state,
          invoiceDispute: action.payload.reasonDispute,
        },
      }
    case GET_MATCHINGS.SUCCESS:
      return {
        ...state,
        currentJob: {
          ...state.currentJob,
          matchings: action.payload['hydra:member'],
        },
      }
    case POST_CLIENT_DATA_REQ.REQUEST:
      return {
        ...state,
        currentJob: {
          ...state.currentJob,
          tempData: action.data,
        },
      }
    case POST_CLIENT_DATA_REQ.SUCCESS:
      return {
        ...state,
        currentJob: {
          ...state.currentJob,
          worksite: {
            ...state.currentJob.worksite,
            address: {
              ...state.currentJob.worksite.address,
              street:
                state.currentJob.tempData.street ||
                state.currentJob.worksite.address.street,
              postcode:
                state.currentJob.tempData.postCode ||
                state.currentJob.worksite.address.postCode,
              city:
                state.currentJob.tempData.city ||
                state.currentJob.worksite.address.city,
              additionalInformation:
                state.currentJob.tempData.additionalInformation ||
                state.currentJob.worksite.address.additionalInformation,
              parking:
                state.currentJob.tempData.parking ||
                state.currentJob.worksite.address.parking,
              elevator:
                state.currentJob.tempData.elevator ||
                state.currentJob.worksite.address.elevator,
              type:
                state.currentJob.tempData.type ||
                state.currentJob.worksite.address.type,
            },
            order: {
              ...state.currentJob.worksite.order,
              customer: {
                ...state.currentJob.worksite.order.customer,
                phoneNumber:
                  state.currentJob.tempData.phoneNumber ||
                  state.currentJob.worksite.order.customer.phoneNumber,
              },
            },
          },
          tempData: {},
        },
      }
    case POST_CLIENT_DATA_REQ.FAILURE:
      return {
        ...state,
        currentJob: {
          ...state.currentJob,
          tempData: {},
        },
      }
    case GET_JOB_DIAGNOSTIC.SUCCESS:
      return {
        ...state,
        currentDiagnostic: {
          jobId: action.payload['@id'],
          additionalFeesAmount: action.payload.additionalFeesAmount,
          comment: action.payload.comment,
          newJobRequired: action.payload.newJobRequired,
          createdAt: new Date(action.payload.createdAt),
        },
      }
    case GET_COSTING_INFO_REQ.SUCCESS:
      return {
        ...state,
        currentCosting: action.payload['hydra:member'].pop(),
      }
    default: {
      return state
    }
  }
}
