import React from 'react'

import RenderTextField from 'components/shared/Form/RenderTextField'
import { Field, reduxForm } from 'redux-form'
import { compose } from 'redux'
import i18n from 'providers/i18n/I18nProvider'
import { maxValue, minValue } from 'helpers/form/validationHelpers'
import { applyValidationRules } from 'helpers/utils/validate'
import PropTypes from 'prop-types'

const AmountCosting = ({ setFormIsValid, addAdditionalInfo, translate }) => (
  <Field
    label={translate('job.job_costing.costing_amount')}
    name="amount"
    component={RenderTextField}
    onChange={(event, newValue, previousValue, name) => {
      const isValid = newValue >= 1 && newValue <= 10000
      setFormIsValid(isValid)
      addAdditionalInfo({ [name]: newValue })
    }}
    type="number"
    inputProps={{ step: '0.1' }}
    fullWidth
    margin="normal"
  />
)

AmountCosting.propTypes = {
  setFormIsValid: PropTypes.func.isRequired,
  addAdditionalInfo: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
}

const validate = (values, props) => {
  const fieldsWithRules = {
    ['amount']: [minValue(1), maxValue(10000)],
  }

  return applyValidationRules(fieldsWithRules, values, props)
}

export default compose(
  i18n,
  reduxForm({ form: 'amountCostingForm', validate }),
)(AmountCosting)
