import React, { useState } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import translate from 'providers/i18n/translateService'

import { hasPermission } from 'services/permissions'
import { blockPayments } from 'store/firms/firmActions'
import {
  PERM_FIRM_PAYMENT_BLOCK,
  PERM_FIRM_PAYMENT_UNBLOCK,
} from 'constants/permissions'

import { Button, Link, Typography, withStyles } from '@material-ui/core'
import ConfirmDialog from 'components/shared/ConfirmDialog/ConfirmDialog'
import styles from './FirmDetailsProDirDesactiveBtnStyles'

const FirmDetailsProDirPaymentsBlockBtn = ({
  classes,
  hasPaymentBlocked,
  changeBlockPayments,
}) => {
  const [confirmDialogOpened, setDialogOpened] = useState(false)
  const title = hasPaymentBlocked ? 'Réactiver' : 'Désactiver'
  const hasPaymentBlockDisabled = !hasPermission(PERM_FIRM_PAYMENT_BLOCK)
  const hasPaymentUnBlockDisabled = !hasPermission(PERM_FIRM_PAYMENT_UNBLOCK)
  const disabled = hasPaymentBlocked
    ? hasPaymentUnBlockDisabled
    : hasPaymentBlockDisabled

  const handleResponse = ({ currentTarget: { value } }) => {
    if (value === 'true') {
      changeBlockPayments(hasPaymentBlocked)
    }
    setDialogOpened(!confirmDialogOpened)
  }

  return (
    <>
      <Button
        name="desactivateInProDirectory"
        className={classes.root}
        color="primary"
        component={Link}
        onClick={() => setDialogOpened(true)}
        disabled={disabled}
      >
        {hasPaymentBlocked
          ? translate('resources.firms.fields.notBlockPayments')
          : translate('resources.firms.fields.blockPayments')}
      </Button>
      {disabled && (
        <Typography
          style={{ fontSize: '0.755rem', color: 'rgb(181, 181, 181)' }}
        >
          {translate('paymentBlock.permission.message')}
        </Typography>
      )}
      {confirmDialogOpened && (
        <ConfirmDialog
          title={translate('paymentsBlock.confirmation.title', { title })}
          onClose={handleResponse}
        >
          <Typography>
            {hasPaymentBlocked
              ? translate('paymentsNotBlock.confirmation.description')
              : translate('paymentsBlock.confirmation.description')}
          </Typography>
        </ConfirmDialog>
      )}
    </>
  )
}

FirmDetailsProDirPaymentsBlockBtn.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    permissionText: PropTypes.string,
  }).isRequired,
  hasPaymentBlocked: PropTypes.bool,
  changeBlockPayments: PropTypes.func.isRequired,
}

FirmDetailsProDirPaymentsBlockBtn.defaultProps = {
  hasPaymentBlocked: false,
}

const mapDispatchToProps = dispatch => ({
  changeBlockPayments: hasPaymentBlocked =>
    dispatch(blockPayments.request(hasPaymentBlocked)),
})

export default compose(
  connect(null, mapDispatchToProps),
  withStyles(styles),
)(FirmDetailsProDirPaymentsBlockBtn)
