import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import i18n from 'providers/i18n/I18nProvider'
import { reduxForm, Field, formValueSelector } from 'redux-form'
import { FormControl, Grid } from '@material-ui/core'
import RenderSelectMultipleAutocompleteField from 'components/shared/Form/RenderSelectMultipleAutocompleteField'
import RenderCheckbox from 'components/shared/Form/RenderCheckbox'
import RenderRadio from 'components/shared/Form/RenderRadio'
import RenderTextField from 'components/shared/Form/RenderTextField'
import FieldPlace from 'components/shared/FieldPlace/FieldPlace'
import { EDIT_FIRM_INTERVENTION_ZONE_FORM } from 'constants/forms'
import {
  FIRM_MATCHING_OPTION_DEPARTMENT,
  FIRM_MATCHING_OPTION_RADIUS,
  FIRM_MATCHING_OPTIONS,
  FIRM_MATCHING_RADIUSES,
  FIRM_MATCHING_RADIUS_VALUES,
} from 'constants/firms'
import { showNotification } from 'store/Application/ApplicationActions'
import translate from 'providers/i18n/translateService'
import { required, requiredArray } from 'helpers/form/validationHelpers'
import { ERROR } from 'constants/variant'
import { styles } from './FirmInterventionZoneStyle'

const validate = (values, props) => {
  const { isFirmValidatedForPackage } = props

  const errors = {}

  if (!isFirmValidatedForPackage) {
    return errors
  }

  errors.matchingOption = required(values.matchingOption, values, props)

  if (values.matchingOption === FIRM_MATCHING_OPTION_DEPARTMENT) {
    errors.postcodes = requiredArray(values.postcodes, values, props)
  }

  if (values.matchingOption === FIRM_MATCHING_OPTION_RADIUS) {
    errors.matchingRadius = required(values.matchingRadius, values, props)
    errors.matchingAddress = required(values.matchingAddress, values, props)
    errors.matchingAddress = requiredArray(values.matchingPoint, values, props)
  }

  return errors
}

const FirmInterventionZone = props => {
  const {
    classes,
    translate,
    departments,
    handleSubmit,
    matchingOption,
    matchingPoint,
    matchingRadius,
    isFirmValidatedForPackage,
    forceValidation,
    change,
  } = props

  useEffect(() => {
    if (isFirmValidatedForPackage) {
      forceValidation()
    }
  }, [isFirmValidatedForPackage, forceValidation])

  const onAutoCompleteLocation = place => {
    const { geometry, formatted_address } = place
    change('matchingPoint', {
      lat: geometry.location.lat().toString(),
      lng: geometry.location.lng().toString(),
    })
    change('matchingAddress', formatted_address)
  }

  const onChange = () => {
    change('matchingPoint', null)
  }

  return (
    <FormControl className={classes.formControl}>
      <form onSubmit={handleSubmit}>
        <Grid>
          <Grid container justifyContent="flex-start" direction="row">
            <Field
              name="matchingOption"
              component={RenderRadio}
              className={classes.checkboxInterventionZone}
              choices={FIRM_MATCHING_OPTIONS.map(option => ({
                name: translate(`resources.firms.fields.${option}`),
                value: option,
                key: option,
              }))}
            />
          </Grid>
          {matchingOption === FIRM_MATCHING_OPTION_DEPARTMENT && (
            <Grid>
              <Field
                name="checkBox"
                isDisabled
                customStyle={{ display: 'none' }}
                component={RenderCheckbox}
              />
              <Field
                name="postcodes"
                id="postcodesAutocompleteSelect"
                className={classes.fullWidth}
                choices={departments}
                component={RenderSelectMultipleAutocompleteField}
                label={translate('resources.firms.accordions.interventionArea')}
                optionValue="code"
                optionText="name"
              />
            </Grid>
          )}
          {matchingOption === FIRM_MATCHING_OPTION_RADIUS && (
            <Grid>
              <FieldPlace
                placeholder={translate(
                  'resources.firms.fields.intervention_area_address',
                )}
                types={['address']}
                name="matchingAddress"
                onAutoCompleteLocation={onAutoCompleteLocation}
                point={matchingPoint}
                radius={FIRM_MATCHING_RADIUS_VALUES[matchingRadius]}
                onChange={onChange}
                className={classes.inputTextInterventionZone}
              />
              <Field
                name="checkBox"
                isDisabled
                customStyle={{ display: 'none' }}
                component={RenderCheckbox}
              />
              <Field
                name="matchingPoint"
                disabled
                style={{ display: 'none' }}
                component={RenderTextField}
              />
              <Field
                name="matchingRadius"
                component={RenderRadio}
                className={classes.checkboxArea}
                choices={FIRM_MATCHING_RADIUSES.map(option => ({
                  name: translate(`resources.firms.fields.${option}`),
                  value: option,
                  key: option,
                }))}
              />
              <div id="basicMap" className={classes.map} />
            </Grid>
          )}
        </Grid>
      </form>
    </FormControl>
  )
}

FirmInterventionZone.propTypes = {
  classes: PropTypes.shape({
    formControl: PropTypes.string.isRequired,
    map: PropTypes.string.isRequired,
    inputTextInterventionZone: PropTypes.string.isRequired,
    checkboxInterventionZone: PropTypes.string.isRequired,
    checkboxArea: PropTypes.string.isRequired,
    fullWidth: PropTypes.string.isRequired,
  }).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  isFirmValidatedForPackage: PropTypes.bool.isRequired,
  departments: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  forceValidation: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  matchingOption: PropTypes.string,
  matchingPoint: PropTypes.shape({
    lat: PropTypes.string.isRequired,
    lng: PropTypes.string.isRequired,
  }),
  matchingRadius: PropTypes.string,
  initialValues: PropTypes.shape({
    postcodes: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      }),
    ),
    matchingOption: PropTypes.string,
    matchingRadius: PropTypes.string,
    matchingAddress: PropTypes.string,
    matchingPoint: PropTypes.shape({
      lat: PropTypes.string.isRequired,
      lng: PropTypes.string.isRequired,
    }),
  }).isRequired,
}

FirmInterventionZone.defaultProps = {
  matchingOption: null,
  matchingPoint: null,
  matchingRadius: null,
}

const mapStateToProps = state => ({
  matchingOption: formValueSelector(EDIT_FIRM_INTERVENTION_ZONE_FORM)(
    state,
    'matchingOption',
  ),
  matchingPoint: formValueSelector(EDIT_FIRM_INTERVENTION_ZONE_FORM)(
    state,
    'matchingPoint',
  ),
  matchingRadius: formValueSelector(EDIT_FIRM_INTERVENTION_ZONE_FORM)(
    state,
    'matchingRadius',
  ),
})

export default compose(
  i18n,
  connect(mapStateToProps),
  reduxForm({
    form: EDIT_FIRM_INTERVENTION_ZONE_FORM,
    enableReinitialize: true,
    onSubmitFail: (errors, dispatch) => {
      dispatch(
        showNotification({
          payload: {
            message: translate('resources.firms.validation.onSubmitFail'),
            messageType: ERROR,
          },
        }),
      )
    },
    validate,
  }),
  withStyles(styles),
)(FirmInterventionZone)
