import { compose } from 'redux'
import { connect } from 'react-redux'
import { currentCostingSelector } from 'store/jobs/jobSelectors'
import { fileLoadSuccess, uploadJobCosting } from 'store/upload/uploadActions'
import { openFile } from 'store/proDocuments/proDocumentActions'

import { getCostingInfo, postCostingValidation } from 'store/jobs/jobActions'
import i18n from 'providers/i18n/I18nProvider'

import JobCosting from './JobCosting'

const mapStateToProps = state => ({
  currentCosting: currentCostingSelector(state),
})

const mapDispatchToProps = dispatch => ({
  dispatchGetCostingInfo: jobIri =>
    dispatch(getCostingInfo.request({ jobIri })),
  dispatchRequestCostingValidation: (costingData, jobIri) =>
    dispatch(postCostingValidation.request({ ...costingData, jobIri })),
  dispatchOpenFile: ({ fileId, fileType }) =>
    dispatch(openFile({ fileId, fileType })),
  onSuccessFileLoad: fileName => dispatch(fileLoadSuccess(fileName)),
  onValidationDocumentUploaded: (files, additionalInfo) =>
    dispatch(uploadJobCosting.request({ files, additionalInfo })),
})

export default compose(
  i18n,
  connect(mapStateToProps, mapDispatchToProps),
)(JobCosting)
