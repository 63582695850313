import { defaultSearchParams } from './searchParamsHelper'
/**
 * This object map firms' filters fields name to corresponding attributesForFaceting name into algolia
 * @type {Object}
 */
const mapping = {
  departments: 'firmProductSubscription.postcodes',
  packages: 'firmProductSubscription.productSubscriptions.productCode',
  trades: 'trades',
  contractOptions: 'contractOptions',
  origins: 'origin',
  onboardingScore: 'onboardingScore',
  verifiedDocuments: 'verifiedDocuments',
  packageStatus: 'packageStatus',
  packageStatusV2: 'packageStatusV2',
  mandatoryDocumentsStatus: 'mandatoryDocumentsStatus',
  suspensionReason: 'suspensionReason',
  paymentBlocked: 'paymentBlocked',
}

/**
 * Remove an attribute from facetFilters, for example if we have this data in input:
 * facetFilters = [
 *     ['trades:Electricien', 'trades:Peintre'],
 *     ['contractOptions:PACKAGE', 'contractOptions:PICK'],
 *     ['whatever:whatever'],
 * ]
 * attribute = 'trades'
 * we will have this in output:
 * [
 *     ['contractOptions:PACKAGE', 'contractOptions:PICK'],
 *     ['whatever:whatever'],
 * ]
 * @param  {Array} facetFilters All filters sent to algolia
 * @param  {string} attribute
 * @return {Array}
 */
export const cleanFacetFilters = (facetFilters, attribute) => {
  const filters = []
  const regex = `${mapping[attribute]}:`
  facetFilters?.forEach(facetAttributeFilters => {
    const attributeFilter = []
    facetAttributeFilters?.forEach(subFacetFilter => {
      if (subFacetFilter?.search(regex) === -1) {
        attributeFilter?.push(subFacetFilter)
      }
    })

    if (attributeFilter.length > 0) {
      filters?.push(attributeFilter)
    }
  })

  return [...filters]
}

/**
 * @param  {string} filterAttribute
 * @param  {Array} values
 * @return {Array}
 */
export const formatNewFacet = (filterAttribute, values) =>
  values?.map(value => `${mapping[filterAttribute]}:${value}`)

/**
 * @param {Array} facetFilters
 * @param {string} filterAttribute
 * @param {Array} values
 */
export const addFacetFilter = (facetFilters, filterAttribute, values) => {
  if ((values || []).length > 0) {
    return [
      ...cleanFacetFilters(facetFilters, filterAttribute),
      [...formatNewFacet(filterAttribute, values)],
    ]
  }

  return [...cleanFacetFilters(facetFilters, filterAttribute)]
}

export const buildTagFilters = tagFilters => [
  ...defaultSearchParams.tagFilters,
  ...tagFilters,
]
