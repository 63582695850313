import {
  all,
  put,
  select,
  takeLatest,
  takeEvery,
  call,
} from 'redux-saga/effects'
import { reset, change, initialize } from 'redux-form'
import {
  handleRequest,
  handleAlgoliaRequest,
  handleAlgoliaFacetsRequest,
} from 'helpers/store/sagasHelpers'
import get from 'lodash/get'
import translate from 'providers/i18n/translateService'
import proApiClient from 'services/httpClient/proApiClient'
import engineClient from 'services/httpClient/engineClient'
import {
  searchFirms,
  searchFirmsByPackageStatusFacets,
  searchFirmsByMandatoryDocumentsStatusStatusFacets,
  searchFirmsByDocumentsVerifiedStatusStatusFacets,
} from 'services/algolia'
import {
  convertArrayToObject,
  formatIriToId,
  removeSpaces,
} from 'helpers/utils/common'
import { dateFormatter } from 'helpers/date'
import { selectTransition } from 'helpers/utils/firm/firm'
import { JOB_DONE_STATUS } from 'constants/Jobs'
import { EMPTY_PATH } from 'constants/routes'
import {
  FIELD_LEGAL_INFORMATIONS_IBAN,
  FIELD_LEGAL_INFORMATIONS_SWIFT_CODE,
  FIELD_LEGAL_INFORMATIONS_VAT_NUMBER,
  FIRM_VATS,
  PACKAGE_STATUS_CANDIDATE,
  PACKAGE_STATUS_VALIDATED,
  FIRM_MATCHING_OPTION_DEPARTMENT,
  VALIDATE_CANDIDATURE_TRANSITION,
  FORCE_VALIDATE_TRANSITION,
  VALIDATE_TEST_TRANSITION,
  RETRY_TEST_TRANSITION,
  BLACKLIST_TRANSITION,
} from 'constants/firms'
import {
  EDIT_FIRM_DETAILS_PRO_FORM,
  EDIT_FIRM_DETAILS_PACKAGES_FORM,
  EDIT_FIRM_DETAILS_LEGAL_INFORMATIONS_FORM,
  EDIT_FIRM_DETAILS_INFO_PACKAGE_FORM,
  EDIT_FIRM_INTERVENTION_ZONE_FORM,
  ADD_FIRM_COMMENT_FORM,
  FILTER_FIRMS_LIST_FORM,
} from 'constants/forms'
import { showNotification } from 'store/Application/ApplicationActions'
import { ADD_FIRM_COMMENT } from 'store/firmComments/firmCommentActions'
import { handleSaveFirmComment } from 'store/firmComments/firmCommentSagas'
import { ISO_SHORT } from 'constants/date'
import { SUCCESS, INFO, ERROR } from 'constants/variant'
import {
  GET_FIRMS_LIST,
  getFirmsList,
  GET_SINGLE_FIRM,
  getSingleFirm,
  getCountJobsDone,
  GET_COUNT_JOBS_DONE,
  UPDATE_FIRM_STATE,
  resetFirmState,
  updateFirmState,
  updateFirmDetails,
  UPDATE_FIRM_DETAILS_PRO,
  UPDATE_FIRM_DETAILS_PACKAGES,
  UPDATE_FIRM_DETAILS_LEGAL_INFORMATIONS,
  UPDATE_FIRM_DETAILS_INFORMATIONS_PACKAGE,
  UPDATE_FIRM_DETAILS_INTERVENTION_ZONE,
  getFirmContract,
  GET_FIRM_CONTRACT,
  SET_FIRMS_LIST_FILTERS,
  setFirmListSearchParams,
  RESET_FILTER_FIRM,
  FIRM_GET_SINGLE_FILE,
  firmGetSingleFile,
  REMOVE_REGISTER_FILE,
  removeRegisterFile,
  firmSetFirmFiles,
  getCountFirmsByStatuses,
  GET_COUNT_FIRMS_BY_STATUSES,
  getCountFirmsByDocumentStatuses,
  GET_COUNT_FIRMS_BY_DOC_STATUSES,
  getCountFirmsByDocVerifiedStatuses,
  GET_COUNT_FIRMS_BY_DOC_VERIFIED_STATUSES,
  updateFirmDetailsTransition,
  DESACTIVATE_FIRM_DIRECTORY,
  desactivateFirmDirectory,
  GET_FIRM_CERTIFICATES,
  getFirmCertificates,
  saveFirmCertificates,
  SAVE_FIRM_CERTIFICATES,
  UPDATE_FIRM_COLLABORATORS,
  updateFirmCollaborators,
  UPDATE_USER_SUSPENSION_REASONS,
  updateUserSuspensionReasons,
  BLOCK_PAYMENTS,
  blockPayments,
} from './firmActions'
import {
  defaultSearchParams,
  currentFirmIriSelector,
  currentFirmSelector,
  isFirmReadyToSubmitSelector,
  payloadToSubmitSelector,
  currentFirmProductSubscriptionSelector,
  firmLegalInformationsIriSelector,
  firmFilesCompletedAtSelector,
  searchParamsSelector,
  currentFirmIdSelector,
  firmPackageStatusCandidateOriginLabelSelector,
  firmContractOptionsLabelSelector,
  getCurrentFirmContractOptionsUris,
  firmFilesCompletedAtFormattedSelector,
  categoriesSelectedSelector,
  expirationDateSelectedSelector,
  certificateReferenceSelectedSelector,
  firmCertificateFormTypeValueSelector,
} from './firmSelectors'

const LOADED_WINDOW_DOCUMENT_READY_STATE = 'complete'
const firmVats = convertArrayToObject(FIRM_VATS, 'key')

export function* fetchFirmList({ searchParams }) {
  yield handleAlgoliaRequest(getFirmsList, searchFirms(searchParams), {
    triggerModalLoader: true,
  })
}

export function* fetchGetCountFirmsByStatuses() {
  try {
    const params = yield select(searchParamsSelector)

    yield handleAlgoliaFacetsRequest(
      getCountFirmsByStatuses,
      searchFirmsByPackageStatusFacets(params),
      {},
    )
  } catch (e) {
    console.error(e)
  }
}

export function* fetchGetCountFirmsByDocumentStatuses() {
  try {
    const params = yield select(searchParamsSelector)

    yield handleAlgoliaFacetsRequest(
      getCountFirmsByDocumentStatuses,
      searchFirmsByMandatoryDocumentsStatusStatusFacets(params),
      {},
    )
  } catch (e) {
    console.error(e)
  }
}

export function* fetchGetCountFirmsByDocVerifiedStatuses() {
  try {
    const params = yield select(searchParamsSelector)

    yield handleAlgoliaFacetsRequest(
      getCountFirmsByDocVerifiedStatuses,
      searchFirmsByDocumentsVerifiedStatusStatusFacets(params),
      {},
    )
  } catch (e) {
    console.error(e)
  }
}
export function* handleGetSingleFirm(searchParams) {
  if (!searchParams || !searchParams.firmId) {
    return
  }
  const firmId = formatIriToId(searchParams.firmId)

  try {
    yield* handleRequest({
      requestActions: getSingleFirm,
      promise: call(proApiClient.get, `/api/firms/${firmId}`),
      actionParams: {
        triggerModalLoader: true,
      },
    })
  } catch (e) {
    console.error(e)
    yield put(
      showNotification({
        payload: {
          messageType: ERROR,
          message: translate('firm.firm_show.req_failure'),
        },
      }),
    )
  }
}

export function* handleSetFirmsFilters({ filters }) {
  const facets = []
  const formValues = []

  filters.forEach(filter => {
    const fieldValues = []
    const facet = []
    filter.values.forEach(value => {
      facet.push(`${filter.field}:${value.code}`)
      fieldValues.push({
        label: value.name,
        value: value.code,
      })
    })

    facets.push(facet)
    formValues.push({
      field: filter.field,
      values: fieldValues,
    })
  })

  const searchParams = {
    searchParams: {
      ...defaultSearchParams.searchParams,
      facetFilters: facets,
    },
  }

  yield put(initialize(FILTER_FIRMS_LIST_FORM))
  yield put(setFirmListSearchParams(searchParams))
  yield put(reset(FILTER_FIRMS_LIST_FORM))

  // eslint-disable-next-line no-restricted-syntax
  for (const formValue of formValues) {
    yield put(change(FILTER_FIRMS_LIST_FORM, formValue.field, formValue.values))
  }
}

export function* handleResetFilterFirm() {
  // reset redux form
  yield put(initialize(FILTER_FIRMS_LIST_FORM))
  yield put(reset(FILTER_FIRMS_LIST_FORM))
  // force search reloading with default params
  yield put(getFirmsList.request(defaultSearchParams))
}

export function* handleGetCountJobsDone(searchParams) {
  if (!searchParams || !searchParams.firmId) {
    return
  }

  const firmId = formatIriToId(searchParams.firmId)

  try {
    yield* handleRequest({
      requestActions: getCountJobsDone,
      promise: call(
        engineClient.get,
        `jobs?status=${JOB_DONE_STATUS}&acceptedPro.id=${firmId}`,
      ),
    })
  } catch (e) {
    console.error(e)
  }
}

function* handleFirmDetailsProUpdate({ payload }) {
  const currentFirm = yield select(currentFirmSelector)
  const usersPayload = currentFirm.users
  if (usersPayload && usersPayload.length > 0) {
    usersPayload[0].gender = payload.proGender
    usersPayload[0].firstName = payload.proFirstName
    usersPayload[0].lastName = payload.proLastName
  }

  const data = {
    fixedPhoneNumber: payload.fixedPhoneNumber,
    mobilePhoneNumber: payload.mobilePhoneNumber,
    packageStatusV2: payload.packageStatusV2,
    verifiedDocuments: payload.verifiedDocuments,
    users: usersPayload,
    transitionName: selectTransition(
      currentFirm.packageStatusV2,
      payload.packageStatusV2,
    ),
  }

  if (
    PACKAGE_STATUS_CANDIDATE.code !== currentFirm.packageStatusV2 &&
    PACKAGE_STATUS_CANDIDATE.code === payload.packageStatusV2
  ) {
    data.packageStatusCandidateOrigin = 'bo'
  }

  const filesCompletedAt = yield select(firmFilesCompletedAtSelector)

  if (
    payload.packageStatusV2 === PACKAGE_STATUS_VALIDATED.code &&
    !filesCompletedAt
  ) {
    data.filesCompletedAt = dateFormatter(new Date(), ISO_SHORT)
  }

  yield put(
    updateFirmState({
      form: EDIT_FIRM_DETAILS_PRO_FORM,
      payload: data,
    }),
  )
}

const formatVat = subjectToVat => {
  if (subjectToVat === '') {
    return null
  }

  return firmVats[subjectToVat].code
}

function* handleFirmDetailsInformationsPackageUpdate({ payload }) {
  yield put(
    updateFirmState({
      form: EDIT_FIRM_DETAILS_INFO_PACKAGE_FORM,
      payload: {
        isRemoval: payload.isRemoval,
        isSaturday: payload.isSaturday,
        isSunday: payload.isSunday,
        isWeekend: payload.isWeekend,
        storeCodes: payload.storeCodes.map(storeCode => storeCode.value) || [],
        reliable: payload.reliable,
        origin: payload.origin || null,
        manualRating: payload.manualRating
          ? payload.manualRating.toString().replace(/,/, '.')
          : null,
      },
    }),
  )
}

function* handleFirmDetailsLegalInformations({ payload }) {
  const iri = yield select(firmLegalInformationsIriSelector)

  const {
    capitalAmount,
    registrationNumber,
    subjectToVat,
    ...firmLegalData
  } = payload
  if (capitalAmount !== null && capitalAmount !== '') {
    firmLegalData.capitalAmount = parseInt(capitalAmount, 10)
  }

  // Clean sensitives field before sending them
  ;[
    FIELD_LEGAL_INFORMATIONS_VAT_NUMBER,
    FIELD_LEGAL_INFORMATIONS_SWIFT_CODE,
    FIELD_LEGAL_INFORMATIONS_IBAN,
  ].forEach(field => {
    if (get(firmLegalData, field)) {
      firmLegalData[field] = removeSpaces(firmLegalData[field])
    }
  })

  if (iri !== undefined) {
    firmLegalData['@id'] = iri
  }

  firmLegalData.subjectToVat = formatVat(subjectToVat)

  yield put(
    updateFirmState({
      form: EDIT_FIRM_DETAILS_LEGAL_INFORMATIONS_FORM,
      payload: {
        firmLegalData,
      },
    }),
  )
}

function* handleFirmDetailsPackages({ payload }) {
  yield put(
    updateFirmState({
      form: EDIT_FIRM_DETAILS_PACKAGES_FORM,
      payload: {
        packages: payload,
      },
    }),
  )
}

function* getProductSubscriptionPayload(matchingData, products) {
  const currentFirmProductSubscription = yield select(
    currentFirmProductSubscriptionSelector,
  )

  const productSubscriptions = products
    ? Object.keys(products).reduce((acc, code) => {
        if (products[code] !== true) {
          return [...acc]
        }

        return [...acc, { productCode: code }]
      }, [])
    : null

  let productSubscription = null
  if (get(currentFirmProductSubscription, '@id') !== undefined) {
    productSubscription = {
      ...productSubscription,
      ...currentFirmProductSubscription,
    }
  }

  if (matchingData) {
    productSubscription = {
      ...productSubscription,
      ...matchingData,
      productSubscriptions,
    }
  }

  return productSubscription
}

function* handleFirmDetailsInterventionZoneUpdate({
  payload: {
    matchingOption,
    postcodes,
    matchingRadius,
    matchingAddress,
    matchingPoint,
  },
}) {
  let payload = {}
  if (matchingOption === FIRM_MATCHING_OPTION_DEPARTMENT) {
    payload = {
      matchingOption: FIRM_MATCHING_OPTION_DEPARTMENT,
      postcodes: postcodes ? postcodes.map(storeCode => storeCode.value) : [],
    }
  } else {
    payload = {
      matchingOption,
      matchingRadius,
      matchingAddress,
      matchingPoint,
    }
  }

  yield put(
    updateFirmState({
      form: EDIT_FIRM_INTERVENTION_ZONE_FORM,
      payload: {
        productSubscription: payload,
      },
    }),
  )
}

function* handleAddFirmComment({ payload: { firmComment } }) {
  yield put(
    updateFirmState({
      form: ADD_FIRM_COMMENT_FORM,
      payload: {
        firmComment,
      },
    }),
  )
}

const selectNotification = transitionName => {
  switch (transitionName) {
    case VALIDATE_CANDIDATURE_TRANSITION:
    case RETRY_TEST_TRANSITION:
      return {
        success: 'resources.firms.validation_pro.transition_test_success',
        failure: 'resources.firms.validation_pro.transition_failure',
      }
    case BLACKLIST_TRANSITION:
      return {
        success: 'resources.firms.validation_pro.transition_suspend_success',
        failure: 'resources.firms.validation_pro.transition_failure',
      }
    case VALIDATE_TEST_TRANSITION:
    case FORCE_VALIDATE_TRANSITION:
      return {
        success: 'resources.firms.validation_pro.transition_success',
        failure: 'resources.firms.validation_pro.transition_failure',
      }
    default:
      return {
        success: 'firm.firm_update.req_success',
        failure: 'firm.firm_update.req_failure',
      }
  }
}

function* handleFirmUpdate() {
  const isReadyToSubmit = yield select(isFirmReadyToSubmitSelector)
  if (!isReadyToSubmit) {
    return
  }
  const {
    productSubscription,
    packages,
    firmComment,
    ...payload
  } = yield select(payloadToSubmitSelector)
  const currentFirm = yield select(currentFirmSelector)

  try {
    const currentFirmIri = yield select(currentFirmIriSelector)

    const firmProductSubscriptionPayload = yield getProductSubscriptionPayload(
      productSubscription,
      {
        ...packages,
      },
    )

    const currentFirmContractOptions = yield select(
      getCurrentFirmContractOptionsUris,
    )

    // TODO Remove packageStatus (v1 & v2) because must be managed by the transition
    const {
      packageStatus,
      packageStatusV2,
      transitionName,
      ...restPayload
    } = payload

    if (payload.transitionName) {
      yield handleRequest({
        requestActions: updateFirmDetailsTransition,
        promise: call(
          proApiClient.put,
          `${currentFirmIri}/package-status-transition/${transitionName}`,
          {
            ...restPayload,
            firmProductSubscription: firmProductSubscriptionPayload,
            currentFirmContractOptions,
          },
        ),
        actionParams: {
          triggerModalLoader: true,
        },
      })
    } else {
      const newPayload = { packageStatusV2, ...restPayload }

      yield handleRequest({
        requestActions: updateFirmDetails,
        promise: call(proApiClient.put, currentFirmIri, {
          ...newPayload,
          firmProductSubscription: firmProductSubscriptionPayload,
          currentFirmContractOptions,
        }),
        actionParams: {
          triggerModalLoader: true,
        },
      })
    }

    if (firmComment) {
      yield handleSaveFirmComment({
        firmIri: currentFirmIri,
        firmComment,
      })
    }

    yield put(
      showNotification({
        payload: {
          messageType: SUCCESS,
          message: translate(
            selectNotification(payload.transitionName).success,
          ),
        },
      }),
    )

    const originCandidateLabel = yield select(
      firmPackageStatusCandidateOriginLabelSelector,
    )
    yield put(
      change(
        EDIT_FIRM_DETAILS_PRO_FORM,
        'packageStatusCandidateOriginLabel',
        originCandidateLabel,
      ),
    )

    const contractOptLabel = yield select(firmContractOptionsLabelSelector)
    yield put(
      change(
        EDIT_FIRM_DETAILS_PRO_FORM,
        'contractOptionsLabel',
        contractOptLabel,
      ),
    )

    const filesCompletedField = yield select(
      firmFilesCompletedAtFormattedSelector,
    )
    yield put(
      change(
        EDIT_FIRM_DETAILS_PRO_FORM,
        'filesCompletedAtFormatted',
        filesCompletedField,
      ),
    )

    yield put(resetFirmState())
  } catch (e) {
    console.error(e)
    change(
      EDIT_FIRM_DETAILS_PRO_FORM,
      'packageStatusV2',
      currentFirm.packageStatusV2,
    )
    yield put(
      showNotification({
        payload: {
          messageType: ERROR,
          message: translate(
            selectNotification(payload.transitionName).failure,
          ),
        },
      }),
    )
  }
}

function* getFirmCertificatesSaga() {
  yield* handleRequest({
    requestActions: getFirmCertificates,
    promise: call(proApiClient.get, '/api/certificates'),
  })
}

function* saveFirmCertificatesSaga({ payload: firmFile }) {
  const firm = yield select(currentFirmIriSelector)
  const certificate = yield select(firmCertificateFormTypeValueSelector)
  const certificateCategories = yield select(categoriesSelectedSelector)
  const validationDate = yield select(expirationDateSelectedSelector)
  const certificateReference = yield select(
    certificateReferenceSelectedSelector,
  )

  const body = {
    firm,
    certificate,
    certificateCategories,
    firmFile,
    validationDate,
    certificateReference,
  }

  yield* handleRequest({
    requestActions: saveFirmCertificates,
    promise: call(proApiClient.post, '/api/firm_certificates', body),
  })
}

function* getFirmCertificatesFailure() {
  yield put(
    showNotification({
      payload: {
        messageType: ERROR,
        message: translate(
          'resources.firms.fields.documents_deleteUploadFile_failure',
        ),
      },
    }),
  )
}

function* getFirmContractDataRequest({ firmId }) {
  const fileName = 'contract.pdf'

  const newWindow = window.open(EMPTY_PATH)
  yield* handleRequest({
    requestActions: getFirmContract,
    promise: call(proApiClient.get, `/contracts/${firmId}/${fileName}`, {
      responseType: 'blob',
    }),
    actionParams: { newWindow },
  })
}

function getFirmContractDataSuccess({ payload, actionParams: { newWindow } }) {
  const file = new Blob([payload], { type: 'application/pdf' })
  const fileURL = URL.createObjectURL(file)

  /* eslint-disable no-param-reassign */
  newWindow.onload = () => {
    newWindow.location = fileURL
  }
  if (newWindow.document.readyState === LOADED_WINDOW_DOCUMENT_READY_STATE) {
    newWindow.location = fileURL
  }
  /* eslint-ensable no-param-reassign */
}

function getFirmContractDataFailure({ actionParams: { newWindow } }) {
  newWindow.close()
}

function* firmGetSingleFileDataRequest({ iri }) {
  yield* handleRequest({
    requestActions: firmGetSingleFile,
    promise: call(proApiClient.get, iri),
  })
}

const removeRegisterFileRequest = function*({ idFile }) {
  yield* handleRequest({
    requestActions: removeRegisterFile,
    promise: call(proApiClient.delete, `/api/firm_files/${idFile}`),
    actionParams: { idFile },
  })
}

const removeRegisterFileSuccess = function*() {
  yield put(
    showNotification({
      payload: {
        messageType: INFO,
        message: translate(
          'resources.firms.fields.documents_deleteUploadFile_success',
        ),
      },
    }),
  )
}

const removeRegisterFileFailed = function*() {
  yield put(
    showNotification({
      payload: {
        messageType: ERROR,
        message: translate(
          'resources.firms.fields.documents_deleteUploadFile_failure',
        ),
      },
    }),
  )
}

const getSingleFileSuccess = function*(requestResponse) {
  if (!requestResponse.actionParams.savedFile) {
    return
  }

  yield put(
    firmSetFirmFiles({
      payload: {
        type:
          get(requestResponse, 'actionParams.savedFile.fileType') ||
          get(requestResponse, 'payload.type'),
        name:
          get(requestResponse, 'actionParams.savedFile.fileName') ||
          get(requestResponse, 'payload.name'),
        id: requestResponse.payload['@id'],
      },
    }),
  )
}

const desactivateFirmDirectoryRequest = function*() {
  const firmId = yield select(currentFirmIdSelector)

  return yield* handleRequest({
    requestActions: desactivateFirmDirectory,
    promise: call(proApiClient.put, `/api/firms/${firmId}`, {
      displayedInProDirectory: false,
    }),
  })
}

function* handleUpdateFirmCollaborators({
  data: { fixedPhone, mobilePhone, userId },
}) {
  return yield* handleRequest({
    requestActions: updateFirmCollaborators,
    promise: call(proApiClient.put, userId, { fixedPhone, mobilePhone }),
    actionParams: { triggerModalLoader: true },
  })
}

function* handleUpdateFirmCollaboratorsSuccess() {
  yield put(
    showNotification({
      payload: {
        messageType: SUCCESS,
        message: translate('firm.firm_update.req_success'),
      },
    }),
  )
}

function* handleUpdateFirmCollaboratorsFailure() {
  yield put(
    showNotification({
      payload: {
        messageType: ERROR,
        message: translate(
          'resources.firms.fields.documents_saveUploadFile_failure',
        ),
      },
    }),
  )
}

function* handleUpdateUserSuspensionReasons({ data, packageStatusV2 }) {
  const firmId = yield select(currentFirmIdSelector)

  return yield* handleRequest({
    requestActions: updateUserSuspensionReasons,
    promise: call(proApiClient.put, `/api/firms/${firmId}`, {
      suspensionReason: data,
      packageStatusV2,
    }),
    actionParams: { triggerModalLoader: true },
  })
}

function* handleUpdateUserSuspensionReasonsSuccess() {
  yield put(
    showNotification({
      payload: {
        messageType: SUCCESS,
        message: translate('firm.firm_update.req_success'),
      },
    }),
  )
}

function* handleUpdateUserSuspensionReasonsFailure() {
  yield put(
    showNotification({
      payload: {
        messageType: ERROR,
        message: translate(
          'resources.firms.fields.documents_saveUploadFile_failure',
        ),
      },
    }),
  )
}

function* handleUpdatePaymentsBlock({ payload }) {
  const firmId = yield select(currentFirmIdSelector)

  if (!payload) {
    return yield* handleRequest({
      requestActions: blockPayments,
      promise: call(proApiClient.post, `/api/firms/${firmId}/payment/block`),
      actionParams: { firmId },
    })
  }
  return yield* handleRequest({
    requestActions: blockPayments,
    promise: call(proApiClient.post, `/api/firms/${firmId}/payment/unblock`),
    actionParams: { firmId },
  })
}

function* handleUpdatePaymentsBlockSuccess({ actionParams: { firmId } }) {
  yield put(
    showNotification({
      payload: {
        messageType: SUCCESS,
        message: translate('firm.firm_update.req_success'),
      },
    }),
  )
  yield* handleRequest({
    requestActions: getSingleFirm,
    promise: call(proApiClient.get, `/api/firms/${firmId}`),
    actionParams: {
      triggerModalLoader: true,
    },
  })
}

function* handleUpdatePaymentsBlockFailure() {
  yield put(
    showNotification({
      payload: {
        messageType: ERROR,
        message: translate('resources.firms.fields.paymentsBlock'),
      },
    }),
  )
}

export default function*() {
  yield all([
    takeLatest(
      GET_COUNT_FIRMS_BY_STATUSES.REQUEST,
      fetchGetCountFirmsByStatuses,
    ),
    takeLatest(
      GET_COUNT_FIRMS_BY_DOC_STATUSES.REQUEST,
      fetchGetCountFirmsByDocumentStatuses,
    ),
    takeLatest(
      GET_COUNT_FIRMS_BY_DOC_VERIFIED_STATUSES.REQUEST,
      fetchGetCountFirmsByDocVerifiedStatuses,
    ),
    takeLatest(GET_FIRMS_LIST.REQUEST, fetchFirmList),
    takeLatest(GET_SINGLE_FIRM.REQUEST, handleGetSingleFirm),
    takeLatest(GET_COUNT_JOBS_DONE.REQUEST, handleGetCountJobsDone),
    takeLatest(GET_COUNT_JOBS_DONE.SUCCESS, handleGetCountJobsDone),
    takeLatest(UPDATE_FIRM_DETAILS_PRO, handleFirmDetailsProUpdate),
    takeLatest(
      UPDATE_FIRM_DETAILS_INFORMATIONS_PACKAGE,
      handleFirmDetailsInformationsPackageUpdate,
    ),
    takeLatest(
      UPDATE_FIRM_DETAILS_LEGAL_INFORMATIONS,
      handleFirmDetailsLegalInformations,
    ),
    takeLatest(UPDATE_FIRM_DETAILS_PACKAGES, handleFirmDetailsPackages),
    takeLatest(
      UPDATE_FIRM_DETAILS_INTERVENTION_ZONE,
      handleFirmDetailsInterventionZoneUpdate,
    ),
    takeLatest(ADD_FIRM_COMMENT, handleAddFirmComment),
    takeLatest(UPDATE_FIRM_STATE, handleFirmUpdate),
    takeLatest(GET_FIRM_CONTRACT.REQUEST, getFirmContractDataRequest),
    takeLatest(GET_FIRM_CONTRACT.SUCCESS, getFirmContractDataSuccess),
    takeLatest(GET_FIRM_CONTRACT.FAILURE, getFirmContractDataFailure),
    takeLatest(SET_FIRMS_LIST_FILTERS, handleSetFirmsFilters),
    takeLatest(RESET_FILTER_FIRM, handleResetFilterFirm),
    takeEvery(FIRM_GET_SINGLE_FILE.REQUEST, firmGetSingleFileDataRequest),
    takeLatest(REMOVE_REGISTER_FILE.REQUEST, removeRegisterFileRequest),
    takeLatest(REMOVE_REGISTER_FILE.SUCCESS, removeRegisterFileSuccess),
    takeLatest(REMOVE_REGISTER_FILE.FAILURE, removeRegisterFileFailed),
    takeLatest(FIRM_GET_SINGLE_FILE.SUCCESS, getSingleFileSuccess),
    takeLatest(
      DESACTIVATE_FIRM_DIRECTORY.REQUEST,
      desactivateFirmDirectoryRequest,
    ),
    takeLatest(GET_FIRM_CERTIFICATES.REQUEST, getFirmCertificatesSaga),
    takeLatest(GET_FIRM_CERTIFICATES.FAILURE, getFirmCertificatesFailure),
    takeLatest(SAVE_FIRM_CERTIFICATES.REQUEST, saveFirmCertificatesSaga),
    takeLatest(
      UPDATE_FIRM_COLLABORATORS.REQUEST,
      handleUpdateFirmCollaborators,
    ),
    takeLatest(
      UPDATE_FIRM_COLLABORATORS.SUCCESS,
      handleUpdateFirmCollaboratorsSuccess,
    ),
    takeLatest(
      UPDATE_FIRM_COLLABORATORS.FAILURE,
      handleUpdateFirmCollaboratorsFailure,
    ),
    takeLatest(
      UPDATE_USER_SUSPENSION_REASONS.REQUEST,
      handleUpdateUserSuspensionReasons,
    ),
    takeLatest(
      UPDATE_USER_SUSPENSION_REASONS.SUCCESS,
      handleUpdateUserSuspensionReasonsSuccess,
    ),
    takeLatest(
      UPDATE_USER_SUSPENSION_REASONS.FAILURE,
      handleUpdateUserSuspensionReasonsFailure,
    ),
    takeLatest(BLOCK_PAYMENTS.REQUEST, handleUpdatePaymentsBlock),
    takeLatest(BLOCK_PAYMENTS.SUCCESS, handleUpdatePaymentsBlockSuccess),
    takeLatest(BLOCK_PAYMENTS.FAILURE, handleUpdatePaymentsBlockFailure),
  ])
}
