/* CONSUMER */
export const CONSUMER_BACKOFFICE = 'backoffice'
export const CONSUMER_PRO_DESKTOP = 'pro_desktop'
export const CONSUMER_PRO_APP = 'pro_app'
export const CONSUMER_HO_DESKTOP = 'ho_desktop'
export const CONSUMER_ORDER_DESKTOP = 'order_desktop'

/* FFs */
export const CLIENT_RESERVES = 'client_reserves'
export const MANUAL_AFTER_SALE_DIAGNOSTIC = 'manual_after_sale_diagnostic'
export const BLOCK_PAYMENTS = 'block_payments'
export const DIAGNOSTIC_COSTING = 'diagnosticCosting'
