import { all, select, takeLatest, put, call } from 'redux-saga/effects'
import { handleRequest } from 'helpers/store/sagasHelpers'
import client from 'services/httpClient/engineClient'
import {
  SET_INCIDENT_RESPONSIBLE,
  FETCH_INCIDENT,
  setIncidentResponsible,
  POST_INCIDENT_REQ,
  postIncident,
  FETCH_INCIDENT_TYPES,
  fetchIncidentTypes,
  fetchIncident,
  FETCH_INCIDENT_STATUSES,
  fetchIncidentStatuses,
  UPDATE_INCIDENT_STATUS,
  updateIncidentStatus,
  FETCH_INCIDENT_WAITING_FOR_LIST,
  fetchIncidentWaitingForList,
  UPDATE_INCIDENT_WAITING_FOR,
  updateIncidentWaitingFor,
  getCountIncidentsByStatuses,
  GET_COUNT_INCIDENTS_BY_STATUSES,
  STATUS_CHANGE_CONFIRM_DIALOG_DISPLAYED,
  STATUS_CHANGED,
  doDisplayStatusChangeConfirmDialog,
  doDisplayRespSelectDialog,
  RESP_SELECT_DIALOG_DISPLAYED,
} from 'store/incidents/incidentActions'
import { GET_SINGLE_JOB } from 'store/jobs/jobActions'
import {
  formattedJobIdSelector,
  selectIncident,
  worksiteIdSelector,
} from 'store/jobs/jobSelectors'
import {
  incidentTypesSelector,
  postedIncidentIdSelector,
} from 'store/incidents/incidentSelectors'
import { showNotification } from 'store/Application/ApplicationActions'
import { SUCCESS, ERROR } from 'constants/variant'
import {
  POST_INCIDENT_FORM,
  LIST_INCIDENT_STATUSES_FORM,
} from 'constants/forms'
import translate from 'providers/i18n/translateService'
import { change, reset } from 'redux-form'
import {
  INCIDENT_ISSUED_BY_PRO,
  INCIDENT_SOLVED_STATUS,
  INCIDENT_TRANSITIONS,
  INCIDENT_TYPE_CODES_PROPOSING_RESCHEDULING_ON_SOLVE,
} from 'constants/incidents'
import { GEOZONES_DEPARTMENTS } from 'constants/dashboard'
import { getSingleWorksite } from 'store/worksites/worksiteActions'

function* handleSetIncidentResponsibleSaga({ params }) {
  try {
    const {
      responsible,
      incidentId,
      pro,
      doDisplayStatusChangeConfirmDialogAction,
    } = params
    const url = `incidents/${incidentId}`
    const data = {
      responsible,
    }

    if (responsible === INCIDENT_ISSUED_BY_PRO.code) {
      data.pro = pro
    }

    yield* handleRequest({
      requestActions: setIncidentResponsible,
      promise: call(client.put, url, data),
      actionParams: {
        incidentId,
        doDisplayStatusChangeConfirmDialogAction,
      },
    })
  } catch (e) {
    console.error(e)
  }
}

function* handleUpdateIncidentResponsibleSuccessSaga({ actionParams }) {
  const { incidentId, doDisplayStatusChangeConfirmDialogAction } = actionParams

  yield put(
    showNotification({
      payload: {
        message: translate('resources.incidents.update.responsible.success', {
          incidentId,
        }),
        messageType: SUCCESS,
      },
    }),
  )

  if (doDisplayStatusChangeConfirmDialogAction) {
    yield put(
      change(
        LIST_INCIDENT_STATUSES_FORM,
        doDisplayStatusChangeConfirmDialogAction.payload.incidentId,
        doDisplayStatusChangeConfirmDialogAction.payload.newStatus,
      ),
    )
    yield put(doDisplayStatusChangeConfirmDialogAction)
  }
}

function* handleUpdateIncidentResponsibleFailureSaga({ actionParams }) {
  const { incidentId } = actionParams

  yield put(
    showNotification({
      payload: {
        message: translate('resources.incidents.update.responsible.failure', {
          incidentId,
        }),
        messageType: ERROR,
      },
    }),
  )
}

function* fetchIncidentSaga() {
  const jobId = yield select(formattedJobIdSelector)
  const url = `/jobs/${jobId}/incidents`
  yield* handleRequest({
    requestActions: fetchIncident,
    promise: call(client.get, url),
  })
}

function* handlePostIncidentSaga({ data }) {
  const jobId = yield select(formattedJobIdSelector)
  const url = `/jobs/${jobId}/incidents`
  yield* handleRequest({
    requestActions: postIncident,
    promise: call(client.post, url, data),
    actionParams: {
      triggerModalLoader: true,
    },
  })
}

function* handlePostIncidentSuccessSaga({ payload: { generatedJob } }) {
  const incidentId = yield select(postedIncidentIdSelector)
  yield put(
    showNotification({
      payload: {
        message: translate('resources.incidents.post_incident.success', {
          incidentId,
        }),
        messageType: SUCCESS,
      },
    }),
  )
  if (generatedJob !== null) {
    const worksiteId = yield select(worksiteIdSelector)
    yield put(getSingleWorksite.request({ worksiteId }))
  }
}

function* handlePostIncidentFailureSaga() {
  yield put(
    showNotification({
      payload: {
        message: translate('resources.incidents.post_incident.failure'),
      },
      messageType: ERROR,
    }),
  )
}

function* fetchIncidentTypesSaga() {
  const hasTypes = yield select(incidentTypesSelector)

  if (hasTypes.length === 0) {
    try {
      yield* handleRequest({
        requestActions: fetchIncidentTypes,
        promise: call(client.get, '/incident_types'),
      })
    } catch (e) {
      console.error(e)
    }
  }
}

function* resetIncidentFormSaga() {
  yield put(reset(POST_INCIDENT_FORM))
}

function* handleUpdateIncidentStatusSaga({ id, status, timeslots = null }) {
  const jobId = yield select(formattedJobIdSelector)
  const payload = timeslots ? { timeSlots: timeslots } : null

  const url = `/jobs/${jobId}/incidents/${id}/transition/${INCIDENT_TRANSITIONS[status]}`
  try {
    yield* handleRequest({
      requestActions: updateIncidentStatus,
      promise: call(client.post, url, payload),
      actionParams: {
        triggerModalLoader: true,
        incidentId: id,
        timeslots,
      },
    })
  } catch (exception) {
    yield put(reset(LIST_INCIDENT_STATUSES_FORM))
  }
}

function* handleUpdateIncidentStatusSuccessSaga({
  actionParams: { incidentId, timeslots },
  payload: {
    status,
    type: { code },
  },
}) {
  yield put(
    showNotification({
      payload: {
        message: translate('resources.incidents.update.status.success', {
          incidentId,
        }),
        messageType: SUCCESS,
      },
    }),
  )
  const shouldRefreshPage =
    status === INCIDENT_SOLVED_STATUS &&
    INCIDENT_TYPE_CODES_PROPOSING_RESCHEDULING_ON_SOLVE.includes(code) &&
    timeslots

  if (shouldRefreshPage) {
    document.location.reload()
  }
}

function* handleFetchIncidentStatuses() {
  try {
    yield* handleRequest({
      requestActions: fetchIncidentStatuses,
      promise: call(client.get, '/incident_statuses'),
    })
  } catch (e) {
    console.error(e)
  }
}

function* handleGetCountIncidentsByStatusesSaga({ geozone }) {
  let queryParams = {}
  if (geozone !== 'all') {
    if (!GEOZONES_DEPARTMENTS[geozone]) {
      throw new Error(`Geozone ${geozone} is not valid`)
    }
    queryParams = {
      params: {
        geozones: GEOZONES_DEPARTMENTS[geozone],
      },
    }
  }

  yield* handleRequest({
    requestActions: getCountIncidentsByStatuses,
    promise: call(client.get, 'incidents_count/statuses', queryParams),
  })
}

function* handleUpdateIncidentStatusFailureSaga(action) {
  const { error } = action
  let errorMessage = translate('app.generic_error')

  if (error && error.response) {
    const statusCode = error.response.status
    switch (statusCode) {
      // if you want to add an other error message, just create a case corresponding to the status code you want to bind on
      case 412:
        errorMessage = translate(
          'resources.incidents.update.status.timeslot.failure',
        )
        break
      default:
        errorMessage = translate('resources.incidents.update.status.failure')
        break
    }
  }
  yield put(
    showNotification({
      payload: {
        message: errorMessage,
        messageType: ERROR,
      },
    }),
  )
}

function* handleFetchIncidentWaitingForListSaga() {
  const url = `/incident_waiting_for_list`

  try {
    yield* handleRequest({
      requestActions: fetchIncidentWaitingForList,
      promise: call(client.get, url),
    })
  } catch (e) {
    console.error(e)
  }
}

function* handleUpdateIncidentWaitingForSaga(actionParams) {
  const { id, waitingFor } = actionParams
  const url = `/incidents/${id}`
  try {
    yield* handleRequest({
      requestActions: updateIncidentWaitingFor,
      promise: call(client.put, url, { waitingFor: waitingFor || null }),
      actionParams: {
        waitingFor,
        triggerModalLoader: true,
      },
    })
  } catch (e) {
    yield put(reset(LIST_INCIDENT_STATUSES_FORM))
    console.error(e)
  }
}

function* handleUpdateIncidentWaitingForSuccessSaga(actionParams) {
  const { incidentId } = actionParams

  yield put(
    showNotification({
      payload: {
        message: translate('resources.incidents.update.waiting_for.success', {
          incidentId,
        }),
        messageType: SUCCESS,
      },
    }),
  )
}
function* handleUpdateIncidentWaitingForFailureSaga() {
  yield put(
    showNotification({
      payload: {
        message: translate('resources.incidents.update.waiting_for.failure'),
        messageType: ERROR,
      },
    }),
  )
}

function* handleDialogDisplayed({ payload: { displayed } }) {
  if (displayed === true) {
    return
  }

  yield put(reset(LIST_INCIDENT_STATUSES_FORM))
}

function* handleStatusChanged({ payload: { incidentId, newStatus } }) {
  const incident = yield select(selectIncident, incidentId)

  const doDisplayStatusChangeConfirmDialogAction = doDisplayStatusChangeConfirmDialog(
    {
      payload: { incidentId, newStatus, type: incident.type, displayed: true },
    },
  )

  if (incident.responsible !== null || newStatus !== INCIDENT_SOLVED_STATUS) {
    yield put(doDisplayStatusChangeConfirmDialogAction)

    return
  }

  yield put(
    doDisplayRespSelectDialog({
      payload: {
        incidentId,
        newStatus,
        type: incident.type,
        displayed: true,
        doDisplayStatusChangeConfirmDialogAction,
      },
    }),
  )
}

export default function*() {
  yield all([
    takeLatest(
      SET_INCIDENT_RESPONSIBLE.REQUEST,
      handleSetIncidentResponsibleSaga,
    ),
    takeLatest(
      SET_INCIDENT_RESPONSIBLE.FAILURE,
      handleUpdateIncidentResponsibleFailureSaga,
    ),
    takeLatest(
      SET_INCIDENT_RESPONSIBLE.SUCCESS,
      handleUpdateIncidentResponsibleSuccessSaga,
    ),
    takeLatest(
      [
        FETCH_INCIDENT.REQUEST,
        POST_INCIDENT_REQ.SUCCESS,
        SET_INCIDENT_RESPONSIBLE.SUCCESS,
        UPDATE_INCIDENT_STATUS.SUCCESS,
      ],
      fetchIncidentSaga,
    ),
    takeLatest(FETCH_INCIDENT_TYPES.REQUEST, fetchIncidentTypesSaga),
    takeLatest(POST_INCIDENT_REQ.REQUEST, handlePostIncidentSaga),
    takeLatest(POST_INCIDENT_REQ.FAILURE, handlePostIncidentFailureSaga),
    takeLatest(POST_INCIDENT_REQ.SUCCESS, handlePostIncidentSuccessSaga),
    takeLatest(GET_SINGLE_JOB.SUCCESS, resetIncidentFormSaga),
    takeLatest(
      FETCH_INCIDENT_WAITING_FOR_LIST.REQUEST,
      handleFetchIncidentWaitingForListSaga,
    ),
    takeLatest(
      UPDATE_INCIDENT_WAITING_FOR.REQUEST,
      handleUpdateIncidentWaitingForSaga,
    ),
    takeLatest(
      UPDATE_INCIDENT_WAITING_FOR.SUCCESS,
      handleUpdateIncidentWaitingForSuccessSaga,
    ),
    takeLatest(
      UPDATE_INCIDENT_WAITING_FOR.FAILURE,
      handleUpdateIncidentWaitingForFailureSaga,
    ),
    takeLatest(UPDATE_INCIDENT_STATUS.REQUEST, handleUpdateIncidentStatusSaga),
    takeLatest(
      UPDATE_INCIDENT_STATUS.SUCCESS,
      handleUpdateIncidentStatusSuccessSaga,
    ),
    takeLatest(
      UPDATE_INCIDENT_STATUS.FAILURE,
      handleUpdateIncidentStatusFailureSaga,
    ),
    takeLatest(FETCH_INCIDENT_STATUSES.REQUEST, handleFetchIncidentStatuses),
    takeLatest(
      GET_COUNT_INCIDENTS_BY_STATUSES.REQUEST,
      handleGetCountIncidentsByStatusesSaga,
    ),
    takeLatest(
      [STATUS_CHANGE_CONFIRM_DIALOG_DISPLAYED, RESP_SELECT_DIALOG_DISPLAYED],
      handleDialogDisplayed,
    ),
    takeLatest(STATUS_CHANGED, handleStatusChanged),
  ])
}
