import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import i18n from 'providers/i18n/I18nProvider'
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  withStyles,
} from '@material-ui/core'
import {
  DASHBOARD_CARD_TYPE_CUSTOM,
  DASHBOARD_CARD_TYPE_URGENT,
  DASHBOARD_CARD_TYPE_OTHER,
  DASHBOARD_CARD_TYPE_NEW_INCIDENT,
} from 'constants/dashboard'
import styles from './DashboardCardStyles'

const GenericDashboardCard = ({
  classes,
  counter,
  translate,
  customTranslationKey,
  cardTitle,
  history,
  path,
  setFilters,
  filters,
}) => {
  const renderList = () =>
    Object.keys(counter).map(key => (
      <Fragment key={key}>
        {key === DASHBOARD_CARD_TYPE_CUSTOM && (
          <>
            <Typography className={classes.firstNumber}>
              {counter[key] || 0}
            </Typography>
            <Typography className={classes.other}>
              {translate(`dashboard.items.${customTranslationKey}`)}
            </Typography>
          </>
        )}
        {key === DASHBOARD_CARD_TYPE_URGENT && (
          <>
            <Typography className={classes.urgentNumber}>
              {counter[key] || 0}
            </Typography>
            <Typography className={classes.urgentWord}>
              {translate('dashboard.types.urgent', {
                smart_count: counter[key] || 0,
              })}
            </Typography>
          </>
        )}
        {key === DASHBOARD_CARD_TYPE_NEW_INCIDENT && (
          <>
            <Typography className={classes.urgentNumber}>
              {counter[key] || 0}
            </Typography>
            <Typography className={classes.urgentWord}>
              {translate('dashboard.types.new', {
                smart_count: counter[key] || 0,
              })}
            </Typography>
          </>
        )}
        {key === DASHBOARD_CARD_TYPE_OTHER && (
          <>
            <Typography className={classes.other}>
              {counter[key] - counter.urgent || 0}
            </Typography>
            <Typography className={classes.other}>
              {translate('dashboard.types.other', {
                smart_count: counter[key] - counter.urgent || 0,
              })}
            </Typography>
          </>
        )}
      </Fragment>
    ))

  return (
    <Card
      className={classes.card}
      onClick={() => {
        setFilters(filters)
        history.push(path)
      }}
    >
      <CardHeader
        title={
          <Typography className={classes.title}>
            {translate(cardTitle)}
          </Typography>
        }
      />

      <CardContent className={classes.cardContent}>{renderList()}</CardContent>
    </Card>
  )
}

GenericDashboardCard.propTypes = {
  classes: PropTypes.shape({
    card: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    cardContent: PropTypes.string.isRequired,
    firstNumber: PropTypes.string.isRequired,
    urgentNumber: PropTypes.string.isRequired,
    urgentWord: PropTypes.string.isRequired,
    other: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  translate: PropTypes.func.isRequired,
  cardTitle: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  counter: PropTypes.shape({
    urgent: PropTypes.number,
  }).isRequired,
  setFilters: PropTypes.func.isRequired,
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      values: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
        PropTypes.array,
      ]).isRequired,
    }),
  ).isRequired,
  customTranslationKey: PropTypes.string.isRequired,
}

export default compose(
  i18n,
  withRouter,
  withStyles(styles),
)(GenericDashboardCard)
