import { BLUE_QUOTATIS } from 'theme/constants'

export default theme => ({
  root: {
    background: theme.palette.background.main,
  },
  noWrap: {
    whiteSpace: 'nowrap',
  },
  head: {
    background: 'transparent',
  },
  smallFont: {
    '& th': {
      fontSize: '0.65rem',
    },
  },
  urgent: {
    '&:nth-child(2n+1)': {
      backgroundColor: '#F2B6B6',

      '&:hover': {
        backgroundColor: '#EAA4A4',
      },
    },
    '&:nth-child(even)': {
      backgroundColor: '#FBD2D2',

      '&:hover': {
        backgroundColor: '#ECC3C3',
      },
    },
  },
  icon: {
    minWidth: '30px',
    maxWidth: '50px',
    minHeight: '40px',
    margin: 'auto',
    backgroundSize: 'contain',
    display: 'inline-block',
  },
  products: { minWidth: '250px', alignItems: 'center' },
  span: { display: 'block' },
  jobOrderIdContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  imgPartner: {
    width: '32px',
    height: '32px',
    backgroundSize: 'auto',
    marginRight: '10px',
  },
  linkJobOrder: {
    fontWeight: 'bold',
    textDecoration: 'none',
    color: BLUE_QUOTATIS,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  acceptedPro: {
    display: 'block',
    minWidth: '120px',
  },
})
