import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Switch, Route, Redirect } from 'react-router-dom'
import {
  PROS_LIST_PATH,
  PRO_SINGLE,
  JOB_SINGLE_MANUAL_MATCHING,
  ADMIN_PATH_PREFIX,
  JOBS_SEARCH_PATH,
  ALL_INVOICES_LIST_PATH,
  VERSION_PATH_PREFIX,
  SALE_INVOICES_LIST_PATH,
  FEATURES_PATH,
} from 'constants/routes'
import PropTypes from 'prop-types'
import AllInvoices from 'pages/AllInvoices/AllInvoices'
import SaleInvoices from 'pages/SaleInvoices/SaleInvoices'
import AllFirms from 'pages/Firms/AllFirms'
import Features from 'pages/Features/Features'
import Firm from 'pages/FirmDetail/FirmDetail'
import ManualMatching from 'pages/ManualMatching/ManualMatching'
import NotFound from 'pages/NotFound/NotFound'
import LayoutContainer from 'layout/LayoutContainer'
import Version from 'pages/Version/Version'
import { getBarTitleSelector } from 'store/Application/ApplicationSelectors'
import JobRoutes from './JobRoutes'

const AdminRoutes = ({ barTitle, location: { pathname } }) => {
  if (pathname === ADMIN_PATH_PREFIX) {
    return <Redirect to={JOBS_SEARCH_PATH} />
  }

  return (
    <LayoutContainer title={barTitle}>
      <Switch>
        <Route
          exact
          path={PROS_LIST_PATH}
          render={props => <AllFirms {...props} />}
        />
        <Route
          exact
          path={JOB_SINGLE_MANUAL_MATCHING}
          render={props => <ManualMatching {...props} />}
        />
        <Route
          exact
          path={ALL_INVOICES_LIST_PATH}
          render={props => <AllInvoices {...props} />}
        />
        <Route
          exact
          path={SALE_INVOICES_LIST_PATH}
          render={props => <SaleInvoices {...props} />}
        />
        <Route exact path={PRO_SINGLE} render={props => <Firm {...props} />} />
        <Route
          exact
          path={VERSION_PATH_PREFIX}
          render={props => <Version {...props} />}
        />
        <Route
          exact
          path={FEATURES_PATH}
          render={props => <Features {...props} />}
        />
        <JobRoutes pathname={pathname} />
        <Route render={props => <NotFound {...props} />} />
      </Switch>
    </LayoutContainer>
  )
}

AdminRoutes.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  barTitle: PropTypes.string.isRequired,
}

const mapStateToProps = state => ({
  barTitle: getBarTitleSelector(state),
})

export default compose(connect(mapStateToProps, null))(AdminRoutes)
