import {
  generateRequestActions,
  createRequestTypes,
} from 'helpers/store/actionsHelpers'

export const UPLOAD_JOB_BFT = 'UPLOAD_JOB_BFT'
export const UPLOAD_JOB_CERFA = 'UPLOAD_JOB_CERFA'
export const FILE_LOAD_SUCCESS = 'FILE_LOAD_SUCCESS'
export const FILE_LOAD_FAILURE = 'FILE_LOAD_FAILURE'
export const UPLOAD_CERTIFICAT = 'UPLOAD_CERTIFICAT'
export const UPLOAD_DOCUMENT = 'UPLOAD_DOCUMENT'
export const UPLOAD_JOB_COSTING = createRequestTypes('UPLOAD_JOB_COSTING')
export const uploadJobCosting = generateRequestActions(UPLOAD_JOB_COSTING)

export const UPLOAD_DOCUMENT_FILE = createRequestTypes('UPLOAD_DOCUMENT_FILE')
export const uploadDocumentFile = generateRequestActions(UPLOAD_DOCUMENT_FILE)

export const uploadJobBFT = (file, additionalInfo) => {
  const { reserves } = additionalInfo

  return { type: UPLOAD_JOB_BFT, payload: { file, reserves } }
}

export const uploadJobCerfa = (file, additionalInfo) => {
  const { cerfaType } = additionalInfo

  return {
    type: UPLOAD_JOB_CERFA,
    payload: { file, cerfaType },
  }
}

export const uploadCertificat = (file, additionalInfo) => ({
  type: UPLOAD_CERTIFICAT,
  payload: { file, additionalInfo },
})

export const uploadDocument = (files, additionalInfo) => ({
  type: UPLOAD_DOCUMENT,
  payload: { files, additionalInfo },
})

export const fileLoadSuccess = filename => ({
  type: FILE_LOAD_SUCCESS,
  payload: { filename },
})

export const fileLoadFailure = filename => ({
  type: FILE_LOAD_FAILURE,
  payload: { filename },
})
