import {
  generateRequestActions,
  generateSimpleAction,
  createRequestTypes,
} from 'helpers/store/actionsHelpers'

export const UPDATE_FIRM_STATE = 'UPDATE_FIRM_STATE'
export const RESET_FIRM_STATE = 'RESET_FIRM_STATE'
export const SET_FIRMS_LIST_FILTERS = 'SET_FIRMS_LIST_FILTERS'
export const SET_FIRMS_LIST_SEARCH_PARAMS = 'SET_FIRMS_LIST_SEARCH_PARAMS'
export const RESET_FILTER_FIRM = 'RESET_FILTER_FIRM'
export const FIRM_GET_SINGLE_FILE = createRequestTypes('FIRM_GET_SINGLE_FILE')
export const SAVE_FIRM_CERTIFICATES = createRequestTypes(
  'SAVE_FIRM_CERTIFICATES',
)
export const GET_FIRM_CERTIFICATES = createRequestTypes('GET_FIRM_CERTIFICATES')
export const GET_FIRMS_LIST = createRequestTypes('GET_FIRMS_LIST')
export const GET_SINGLE_FIRM = createRequestTypes('GET_SINGLE_FIRM')
export const SAVE_FIRM_CERTIFICAT_FILE = createRequestTypes(
  'SAVE_FIRM_CERTIFICAT_FILE',
)
export const GET_COUNT_JOBS_DONE = createRequestTypes('GET_COUNT_JOBS_DONE')
export const UPDATE_FIRM_DETAILS = createRequestTypes('UPDATE_FIRM_DETAILS')
export const UPDATE_FIRM_DETAILS_TRANSITION = createRequestTypes(
  'UPDATE_FIRM_DETAILS_TRANSITION',
)
export const UPDATE_FIRM_DETAILS_PRO = 'UPDATE_FIRM_DETAILS_PRO'
export const UPDATE_FIRM_DETAILS_PACKAGES = 'UPDATE_FIRM_DETAILS_PACKAGES'
export const UPDATE_FIRM_DETAILS_LEGAL_INFORMATIONS =
  'UPDATE_FIRM_DETAILS_LEGAL_INFORMATIONS'
export const UPDATE_FIRM_DETAILS_INFORMATIONS_PACKAGE =
  'UPDATE_FIRM_DETAILS_INFORMATIONS_PACKAGE'
export const UPDATE_FIRM_DETAILS_INTERVENTION_ZONE =
  'UPDATE_FIRM_DETAILS_INTERVENTION_ZONE'
export const GET_FIRM_CONTRACT = createRequestTypes('GET_FIRM_CONTRACT')
export const updateFirmState = generateSimpleAction(UPDATE_FIRM_STATE)
export const resetFirmState = generateSimpleAction(RESET_FIRM_STATE)
export const setFirmListFilters = generateSimpleAction(SET_FIRMS_LIST_FILTERS)
export const setFirmListSearchParams = generateSimpleAction(
  SET_FIRMS_LIST_SEARCH_PARAMS,
)
export const resetFilterFirm = generateSimpleAction(RESET_FILTER_FIRM)
export const getFirmsList = generateRequestActions(GET_FIRMS_LIST)
export const getSingleFirm = generateRequestActions(GET_SINGLE_FIRM)
export const getCountJobsDone = generateRequestActions(GET_COUNT_JOBS_DONE)
export const updateFirmDetailsTransition = generateRequestActions(
  UPDATE_FIRM_DETAILS_TRANSITION,
)
export const updateFirmDetails = generateRequestActions(UPDATE_FIRM_DETAILS)
export const updateFirmDetailsPro = generateSimpleAction(
  UPDATE_FIRM_DETAILS_PRO,
)
export const updateFirmDetailsPackages = generateSimpleAction(
  UPDATE_FIRM_DETAILS_PACKAGES,
)
export const updateFirmDetailsLegalInformations = generateSimpleAction(
  UPDATE_FIRM_DETAILS_LEGAL_INFORMATIONS,
)
export const updateFirmDetailsInformationsPackage = generateSimpleAction(
  UPDATE_FIRM_DETAILS_INFORMATIONS_PACKAGE,
)
export const updateFirmDetailsInterventionZone = generateSimpleAction(
  UPDATE_FIRM_DETAILS_INTERVENTION_ZONE,
)
export const getFirmContract = generateRequestActions(GET_FIRM_CONTRACT)
export const firmGetSingleFile = generateRequestActions(FIRM_GET_SINGLE_FILE)
export const saveFirmCertificateFile = generateRequestActions(
  SAVE_FIRM_CERTIFICAT_FILE,
)
export const saveFirmCertificates = generateRequestActions(
  SAVE_FIRM_CERTIFICATES,
)
export const getFirmCertificates = generateRequestActions(GET_FIRM_CERTIFICATES)
export const REMOVE_REGISTER_FILE = createRequestTypes('REMOVE_REGISTER_FILE')
export const removeRegisterFile = generateRequestActions(REMOVE_REGISTER_FILE)
export const FIRM_SET_FIRM_FILES = 'FIRM_SET_FIRM_FILES'
export const firmSetFirmFiles = generateSimpleAction(FIRM_SET_FIRM_FILES)

export const GET_COUNT_FIRMS_BY_STATUSES = createRequestTypes(
  'GET_COUNT_FIRMS_BY_STATUSES',
)

export const getCountFirmsByStatuses = generateRequestActions(
  GET_COUNT_FIRMS_BY_STATUSES,
)

export const GET_COUNT_FIRMS_BY_DOC_STATUSES = createRequestTypes(
  'GET_COUNT_FIRMS_BY_DOC_STATUSES',
)

export const getCountFirmsByDocumentStatuses = generateRequestActions(
  GET_COUNT_FIRMS_BY_DOC_STATUSES,
)

export const GET_COUNT_FIRMS_BY_DOC_VERIFIED_STATUSES = createRequestTypes(
  'GET_COUNT_FIRMS_BY_DOC_VERIFIED_STATUSES',
)

export const getCountFirmsByDocVerifiedStatuses = generateRequestActions(
  GET_COUNT_FIRMS_BY_DOC_VERIFIED_STATUSES,
)

export const DESACTIVATE_FIRM_DIRECTORY = createRequestTypes(
  'DESACTIVATE_FIRM_DIRECTORY',
)
export const desactivateFirmDirectory = generateRequestActions(
  DESACTIVATE_FIRM_DIRECTORY,
)

export const SET_ORGANISME_CERTIFICAT = 'SET_ORGANISME_CERTIFICAT'
export const setOrganismeCertficat = generateSimpleAction(
  SET_ORGANISME_CERTIFICAT,
)

export const SET_CATEGORIES_CERTIFICAT_RGE = 'SET_CATEGORIES_CERTIFICAT_RGE'
export const setCategoriesCertificat = generateSimpleAction(
  SET_CATEGORIES_CERTIFICAT_RGE,
)

export const SET_EXPIRATION_DATE_CERTIFICAT = 'SET_EXPIRATION_DATE_CERTIFICAT'
export const setExpirationDateCertificat = generateSimpleAction(
  SET_EXPIRATION_DATE_CERTIFICAT,
)

export const SET_CERTIFICATE_REFERENCE = 'SET_CERTIFICATE_REFERENCE'
export const setCertificateReference = generateSimpleAction(
  SET_CERTIFICATE_REFERENCE,
)

export const UPDATE_FIRM_COLLABORATORS = createRequestTypes(
  'UPDATE_FIRM_COLLABORATORS',
)

export const updateFirmCollaborators = generateRequestActions(
  UPDATE_FIRM_COLLABORATORS,
)

export const UPDATE_USER_SUSPENSION_REASONS = createRequestTypes(
  'UPDATE_USER_SUSPENSION_REASONS',
)

export const updateUserSuspensionReasons = generateRequestActions(
  UPDATE_USER_SUSPENSION_REASONS,
)

export const BLOCK_PAYMENTS = createRequestTypes('BLOCK_PAYMENTS')
export const blockPayments = generateRequestActions(BLOCK_PAYMENTS)
