import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { reduxForm, Field } from 'redux-form'
import {
  Card,
  CardHeader,
  CardContent,
  Avatar,
  FormControl,
  Grid,
  withStyles,
} from '@material-ui/core'
import translate from 'providers/i18n/translateService'
import i18n from 'providers/i18n/I18nProvider'

import { required, validatePhone } from 'helpers/form/validationHelpers'
import { showNotification } from 'store/Application/ApplicationActions'
import { ERROR } from 'constants/variant'
import { EDIT_FIRM_DETAILS_PRO_FORM } from 'constants/forms'
import {
  CANDIDATE_VERIFIED_DOCUMENTS_STATUS,
  FIRM_GENDER,
  PACKAGE_STATUS_PENDING_TEST_JOB,
  PACKAGE_STATUS_IN_PROGRESS_TEST_JOB,
  FIRM_ONBOARDINGSCORE,
  PACKAGE_STATUS_SUSPENDED_TEMPORARY,
  PACKAGE_STATUS_SUSPENDED_DEFINITIVELY,
} from 'constants/firms'
import RenderSelectField from 'components/shared/Form/RenderSelectField'
import RenderTextField from 'components/shared/Form/RenderTextField'
import Icon from 'components/shared/Icon/Icon'

import styles from './FirmDetailsProStyles'
import FirmDetailsProDirectoryStatus from './FirmDetailsProDirectoryStatus'
import FirmDetailsProDirectoryDesactiveBtn from './FirmDetailsProDirectoryDesactiveBtn'
import FirmDetailsProSuspendedReasonsModal from './ProSuspendedReasons/FirmDetailsProSuspendedReasonsModal'
import FirmDetailsProDirPaymentsBlockBtn from './FirmDetailsProDirPaymentsBlockBtn'
import FirmDetailsProDirPaymentBlockStatus from './FirmDetailsProDirPaymentBlockStatus'

const FirmDetailsPro = ({
  classes,
  translate,
  handleSubmit,
  isVerifiedDocumentDisabled,
  availablePackageStatus,
  displayDesactiveBtn,
  hasDirectoryOption,
  initialValues,
  isPaymentBlockFfEnabled,
  hasPaymentBlocked,
}) => {
  const [currentStatus, setCurrentStatus] = useState(null)
  const [popinOpened, setPopinOpened] = useState(false)

  return (
    <>
      {popinOpened && (
        <FirmDetailsProSuspendedReasonsModal
          open={popinOpened}
          handleClose={() => setPopinOpened(false)}
          initialValues={initialValues}
          availablePackageStatus={availablePackageStatus}
          currentStatus={currentStatus}
        />
      )}
      <FormControl className={classes.formControl}>
        <form onSubmit={handleSubmit}>
          <Grid container justifyContent="flex-start" direction="row">
            <Grid item xs={4}>
              <Card className={classes.card}>
                <CardHeader
                  avatar={
                    <Avatar>
                      <Icon className={classes.icon} icon="id.svg" />
                    </Avatar>
                  }
                  classes={{
                    title: classes.titles,
                  }}
                  title={translate('resources.firms.cards.identification')}
                />
                <CardContent>
                  <Field
                    name="name"
                    className={classes.input}
                    component={RenderTextField}
                    label={translate('resources.firms.fields.firm_name')}
                    disabled
                  />
                  <Field
                    name="proGender"
                    id="proGenderSelect"
                    validate={[required]}
                    className={classes.input}
                    component={RenderSelectField}
                    choices={FIRM_GENDER}
                    label={translate('resources.firms.fields.gender')}
                    optionValue="name"
                    optionText={element =>
                      translate(`resources.firms.gender.${element.name}`)
                    }
                    allowEmpty
                  />
                  <Field
                    name="proFirstName"
                    validate={[required]}
                    className={classes.input}
                    component={RenderTextField}
                    label={translate('resources.firms.fields.first_name')}
                  />
                  <Field
                    name="proLastName"
                    validate={[required]}
                    className={classes.input}
                    component={RenderTextField}
                    label={translate('resources.firms.fields.last_name')}
                  />
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={4}>
              <Card>
                <CardHeader
                  avatar={
                    <Avatar>
                      <Icon className={classes.icon} icon="message.svg" />
                    </Avatar>
                  }
                  classes={{
                    title: classes.titles,
                  }}
                  title={translate('resources.firms.cards.contact')}
                />
                <CardContent>
                  <Field
                    name="email"
                    className={classes.input}
                    component={RenderTextField}
                    label={translate('resources.firms.fields.firm_email')}
                    disabled
                  />
                  <Field
                    name="proEmail"
                    className={classes.input}
                    component={RenderTextField}
                    label={translate('resources.firms.fields.email')}
                    disabled
                  />
                  <Field
                    name="mobilePhoneNumber"
                    validate={[validatePhone]}
                    className={classes.input}
                    component={RenderTextField}
                    label={translate(
                      'resources.firms.fields.firm_mobile_phone',
                    )}
                  />
                  <Field
                    name="fixedPhoneNumber"
                    validate={[validatePhone]}
                    className={classes.input}
                    component={RenderTextField}
                    label={translate('resources.firms.fields.firm_fixed_phone')}
                  />
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={4}>
              <Card className={classes.card}>
                <CardHeader
                  avatar={
                    <Avatar>
                      <Icon className={classes.icon} icon="book.svg" />
                    </Avatar>
                  }
                  classes={{
                    title: classes.titles,
                  }}
                  title={translate('resources.firms.cards.miscellaneous')}
                />
                <CardContent>
                  <Field
                    name="legacyId"
                    className={classes.input}
                    component={RenderTextField}
                    label={translate('resources.firms.fields.firm_legacy_id')}
                    disabled
                  />
                  <Field
                    name="accountingNumber"
                    className={classes.input}
                    component={RenderTextField}
                    label={translate(
                      'resources.firms.fields.accounting_number',
                    )}
                    disabled
                  />
                  <Field
                    name="contractOptionsLabel"
                    className={classes.input}
                    component={RenderTextField}
                    label={translate('resources.firms.fields.contract_options')}
                    disabled
                  />
                  <div className={classes.btnContainer}>
                    <FirmDetailsProDirectoryStatus
                      directoryActivated={displayDesactiveBtn}
                      hasDirectoryOption={hasDirectoryOption}
                    />
                    {hasDirectoryOption && displayDesactiveBtn && (
                      <FirmDetailsProDirectoryDesactiveBtn />
                    )}
                  </div>
                  <Field
                    name="packageStatusV2"
                    id="packageStatusSelect"
                    className={classes.input}
                    component={RenderSelectField}
                    choices={availablePackageStatus}
                    label={translate('firm.packages_subscription.label')}
                    optionValue="code"
                    disabled={[
                      PACKAGE_STATUS_PENDING_TEST_JOB.code,
                      PACKAGE_STATUS_IN_PROGRESS_TEST_JOB.code,
                    ].includes(initialValues.packageStatusV2)}
                    onChange={(values, newValue) => {
                      setCurrentStatus(newValue)
                      if (
                        newValue === PACKAGE_STATUS_SUSPENDED_TEMPORARY.code ||
                        newValue === PACKAGE_STATUS_SUSPENDED_DEFINITIVELY.code
                      ) {
                        setPopinOpened(true)
                      }
                    }}
                  />
                  {initialValues.suspensionReason && (
                    <Field
                      name="suspensionReason"
                      className={classes.input}
                      component={RenderTextField}
                      label={translate(
                        'resources.firms.fields.suspensionReason',
                      )}
                      disabled
                    />
                  )}
                  <Field
                    name="packageStatusCandidateOriginLabel"
                    className={classes.input}
                    component={RenderTextField}
                    label={translate(
                      'resources.firms.fields.package_status_candidate_origin',
                    )}
                    disabled
                  />
                  <Field
                    name="filesCompletedAtFormatted"
                    className={classes.input}
                    component={RenderTextField}
                    label={translate(
                      'resources.firms.fields.files_completed_at',
                    )}
                    disabled
                  />
                  <Field
                    name="onboardingScore"
                    className={classes.input}
                    component={RenderSelectField}
                    choices={FIRM_ONBOARDINGSCORE}
                    optionValue="code"
                    label={translate('resources.firms.fields.onboarding_score')}
                    disabled
                  />
                  <Field
                    name="verifiedDocuments"
                    className={classes.input}
                    component={RenderSelectField}
                    choices={CANDIDATE_VERIFIED_DOCUMENTS_STATUS}
                    optionValue="code"
                    label={translate(
                      'resources.firms.fields.documents_verified',
                    )}
                    disabled={isVerifiedDocumentDisabled}
                  />
                  {isPaymentBlockFfEnabled && (
                    <div className={classes.btnContainer}>
                      <FirmDetailsProDirPaymentBlockStatus
                        hasPaymentBlocked={hasPaymentBlocked}
                      />
                      <FirmDetailsProDirPaymentsBlockBtn
                        hasPaymentBlocked={hasPaymentBlocked}
                      />
                    </div>
                  )}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </form>
      </FormControl>
    </>
  )
}

FirmDetailsPro.propTypes = {
  classes: PropTypes.shape({
    formControl: PropTypes.string,
    card: PropTypes.string,
    h3: PropTypes.string,
    input: PropTypes.string,
    titles: PropTypes.string,
    icon: PropTypes.string,
    btnContainer: PropTypes.string,
    cardIcon: PropTypes.string,
  }).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  availablePackageStatus: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  displayDesactiveBtn: PropTypes.bool,
  hasDirectoryOption: PropTypes.bool,
  isVerifiedDocumentDisabled: PropTypes.bool.isRequired,
  initialValues: PropTypes.shape({
    packageStatusV2: PropTypes.string.isRequired,
    onboardingScore: PropTypes.string.isRequired,
    verifiedDocuments: PropTypes.string.isRequired,
    suspensionReason: PropTypes.string,
  }).isRequired,
  isPaymentBlockFfEnabled: PropTypes.bool.isRequired,
  hasPaymentBlocked: PropTypes.bool,
}

FirmDetailsPro.defaultProps = {
  displayDesactiveBtn: false,
  hasDirectoryOption: false,
  hasPaymentBlocked: false,
}

export default compose(
  i18n,
  reduxForm({
    form: EDIT_FIRM_DETAILS_PRO_FORM,
    onSubmitFail: (errors, dispatch) => {
      dispatch(
        showNotification({
          payload: {
            message: translate('resources.firms.validation.onSubmitFail'),
            messageType: ERROR,
          },
        }),
      )
    },
  }),
  withStyles(styles),
)(FirmDetailsPro)
