import { getCurrentCountry } from 'constants/countries'

const allowedKeysForSearch = [
  'query',
  'page',
  'hitsPerPage',
  'filters',
  'facetFilters',
  'orderBy',
  'sort',
  'restrictSearchableAttributes',
]

export const defaultSearchParams = {
  query: '',
  page: 0,
  hitsPerPage: 10,
  tagFilters: [getCurrentCountry()],
  facetFilters: [],
  filters: '',
}

const fieldsMapping = {
  hitsPerPage: 'rowsPerPage',
}

const mapFields = field =>
  fieldsMapping[field] !== undefined ? fieldsMapping[field] : field

const getRestrictSearchableAttributes = name => {
  let restrictSearchableAttributes = ''
  switch (name) {
    case 'postalCode':
      restrictSearchableAttributes = 'postalCode'
      break
    default:
      restrictSearchableAttributes = ''
      break
  }

  return restrictSearchableAttributes ? { restrictSearchableAttributes } : {}
}

const buildSearchParams = (params, name) =>
  allowedKeysForSearch.reduce((acc, currentValue) => {
    if (params[mapFields(currentValue)] !== undefined) {
      return {
        ...acc,
        [currentValue]: params[mapFields(currentValue)],
      }
    }

    if (defaultSearchParams[currentValue] !== undefined) {
      return {
        ...acc,
        [currentValue]: defaultSearchParams[currentValue],
      }
    }

    const restrictSearchableAttributes = getRestrictSearchableAttributes(name)
    return {
      ...acc,
      ...restrictSearchableAttributes,
    }
  }, {})

/**
 * Use default params to search on algolia, and remove useless properties
 * @param  {object} params
 * @param  {object} name
 * @return {object}
 */
export const formatSearchParams = (params, name) => ({
  searchParams: buildSearchParams(params, name),
})
