const styles = theme => ({
  typography: {
    marginBottom: theme.spacing(1),
  },
  textArea: {
    width: '100%',
    height: '80px',
    marginBottom: 15,
  },
  bold: {
    fontWeight: 'bold',
  },
  buttonBigger: theme.buttonBigger,
  buttonSecondary: {
    ...theme.buttonSecondary,
    marginBottom: '10px',
    marginTop: '10px',
  },
  formControl: {
    margin: theme.spacing(1),
    width: 'auto',
    minWidth: '200px',
  },
  simpleInputText: {
    width: '100%',
    fontSize: '0.9rem',
    border: '0',
    borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
    padding: '6px 0 7px',
    '&:focus': {
      outline: '0',
      borderBottom: '2px solid #143C58',
    },
  },
  marginBottom: {
    marginBottom: theme.spacing(1),
  },
  warningAlert: {
    marginBottom: '10px',
  },
  title: {
    marginBottom: '20px',
  },
  formGridPadding: {
    paddingBottom: 15,
  },
})

export default styles
