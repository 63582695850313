import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { reset } from 'redux-form'
import { FILTER_SALE_ALL_INVOICES_LIST_FORM } from 'constants/forms'
import {
  getInvoicesList,
  resetInvoicesList,
  exportInvoicesList,
  setDisplayedInvoice,
} from 'store/invoices/invoiceActions'
import {
  invoicesListSelector,
  searchParamsSelector,
  displayedInvoiceSelector,
} from 'store/invoices/invoiceSelectors'
import { isLoadingSelector } from 'store/Application/ApplicationSelectors'
import AllInvoicesList from './AllInvoicesList'

const AllInvoicesListContainer = ({
  getInvoices,
  dispatchResetInvoicesFilter,
  invoices,
  searchParams,
  isLoading,
  dispatchExportInvoicesList,
  dispatchResetDisplayedInvoice,
  displayedInvoice,
  handleSearchChange,
  handleFilterByType,
  handleFilterByCreationDate,
}) => {
  const loadInvoices = ({ searchParams: searchParamsAlias }) => {
    getInvoices(searchParamsAlias)
  }

  useEffect(() => {
    loadInvoices(searchParams)
  }, [])

  const handleChangePage = (_, page) => {
    const { total, ...params } = searchParams
    loadInvoices({
      searchParams: {
        ...params,
        page,
      },
    })
  }

  const handleChangePerPage = event => {
    const { total, rowsPerPage, page, ...params } = searchParams
    loadInvoices({
      searchParams: {
        ...params,
        page: 0,
        rowsPerPage: event.target.value,
      },
    })
  }

  const handleChangeSort = orderBy => {
    let sort = 'desc'
    if (searchParams.orderBy && searchParams.orderBy === orderBy) {
      sort = searchParams.sort === 'desc' ? 'asc' : 'desc'
    }
    const { total, page, ...params } = searchParams
    loadInvoices({
      searchParams: {
        ...params,
        orderBy,
        sort,
        page: 0,
      },
    })
  }

  const handleSubmit = data => {
    const {
      total,
      page,
      jobId,
      number,
      orderNumber,
      proName,
      homeOwnerName,
      states,
      issuedAt,
      types,
      ...params
    } = searchParams
    loadInvoices({
      searchParams: {
        ...params,
        ...data,
        page: 0,
      },
    })
  }

  const handleReset = () => {
    console.info({ searchParams })
    dispatchResetInvoicesFilter()
  }

  return (
    <AllInvoicesList
      rows={invoices}
      searchParams={searchParams}
      isLoading={isLoading}
      handleSearchChange={handleSearchChange}
      handleChangePage={handleChangePage}
      handleChangePerPage={handleChangePerPage}
      handleChangeSort={handleChangeSort}
      handleFilterByType={handleFilterByType}
      handleFilterByCreationDate={handleFilterByCreationDate}
      handleSubmitForm={handleSubmit}
      handleReset={handleReset}
      handleExport={dispatchExportInvoicesList}
      dispatchResetDisplayedInvoice={dispatchResetDisplayedInvoice}
      displayedInvoice={displayedInvoice}
    />
  )
}

AllInvoicesListContainer.propTypes = {
  getInvoices: PropTypes.func.isRequired,
  dispatchResetInvoicesList: PropTypes.func.isRequired,
  dispatchResetInvoicesFilter: PropTypes.func.isRequired,
  dispatchExportInvoicesList: PropTypes.func.isRequired,
  dispatchResetDisplayedInvoice: PropTypes.func.isRequired,
  invoices: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isLoading: PropTypes.bool.isRequired,
  displayedInvoice: PropTypes.number,
  searchParams: PropTypes.shape({
    jobId: PropTypes.string.isRequired,
    number: PropTypes.string.isRequired,
    orderNumber: PropTypes.string.isRequired,
    proName: PropTypes.string.isRequired,
    homeOwnerName: PropTypes.string.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    states: PropTypes.shape([]).isRequired,
    types: PropTypes.shape([]).isRequired,
    issuedAt: PropTypes.shape([]).isRequired,
    orderBy: PropTypes.string.isRequired,
    sort: PropTypes.string.isRequired,
  }).isRequired,
  handleSearchChange: PropTypes.func.isRequired,
  handleFilterByType: PropTypes.func.isRequired,
  handleFilterByCreationDate: PropTypes.func.isRequired,
}

AllInvoicesListContainer.defaultProps = {
  displayedInvoice: null,
}

const mapStateToProps = state => ({
  invoices: invoicesListSelector(state),
  searchParams: searchParamsSelector(state),
  isLoading: isLoadingSelector(state),
  displayedInvoice: displayedInvoiceSelector(state),
})

const mapDispatchToProps = dispatch => ({
  getInvoices: searchParams => dispatch(getInvoicesList.request(searchParams)),
  dispatchResetInvoicesList: () => dispatch(resetInvoicesList()),
  dispatchResetInvoicesFilter: () =>
    dispatch(reset(FILTER_SALE_ALL_INVOICES_LIST_FORM)),
  dispatchExportInvoicesList: () => dispatch(exportInvoicesList()),
  dispatchResetDisplayedInvoice: () => dispatch(setDisplayedInvoice(null)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AllInvoicesListContainer)
