import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { CardMedia, TableCell, TableRow, withStyles } from '@material-ui/core'
import ViewMoreCell from 'components/shared/Tables/ViewMoreCell'
import { dateFormatter, isDateLate } from 'helpers/date'
import { DATE_FORMAT } from 'constants/date'
import {
  PENDING_START_STATUS,
  PENDING_MANUAL_ASSIGNMENT_STATUS,
  URGENT_JOB_DELAY_IN_DAYS,
  BLOCKED_RECEIPT,
  PENDING_RESCHEDULING_STATUS,
  INCIDENTS,
  MANUAL_INITIAL_AFTER_SALE_TYPE,
  AUTO_AFTER_SALE_TYPE,
  PK_TYPE,
  MANUAL_RESOLUTION_AFTER_SALE_TYPE,
  MANUAL_DIAGNOSTIC_AFTER_SALE_TYPE,
} from 'constants/Jobs'
import { Link } from 'react-router-dom'
import { JOB_PATH_PREFIX } from 'constants/routes'
import i18n from 'providers/i18n/I18nProvider'
import classNames from 'classnames'
import LinkCustom from 'components/shared/Link/LinkCustom'
import Icon from 'components/shared/Icon/Icon'
import { getJobIdFromIri, getPartnerIconUrl } from 'helpers/utils/job/job'
import styles from './JobsSearchStyles'

const JobsSearchTableBody = ({ rows, translate, classes }) =>
  rows.map(row => {
    const {
      worksite,
      status,
      substatus,
      products,
      minDateHo,
      minDatePro,
      acceptedPro,
      validatedAt,
      proPaidAt,
      type,
    } = row

    const isPendingLate =
      row.status === PENDING_START_STATUS && isDateLate(minDatePro, 0)
    const isPendingManualAssignmentLate =
      row.status === PENDING_MANUAL_ASSIGNMENT_STATUS &&
      isDateLate(minDateHo, URGENT_JOB_DELAY_IN_DAYS)
    const isBlockedReceipt = row.substatus === BLOCKED_RECEIPT
    const isDateUpdatedLate =
      row.status === PENDING_RESCHEDULING_STATUS &&
      (isDateLate(minDatePro, URGENT_JOB_DELAY_IN_DAYS) ||
        isDateLate(minDateHo, URGENT_JOB_DELAY_IN_DAYS))

    const isClassUrgent =
      isPendingLate ||
      isBlockedReceipt ||
      isPendingManualAssignmentLate ||
      isDateUpdatedLate

    const partnerUrl = getPartnerIconUrl(
      worksite.partner ? worksite.partner.id : null,
    )

    const certificateIcons = {
      rge: 'rge.svg',
      cerfa: 'cerfa.svg',
    }

    const certificates = {}
    products.forEach(product => {
      if (product.requiredCertificate) {
        certificates[product.requiredCertificate] = product.requiredCertificate
      }
    })

    const jobUrl = `${JOB_PATH_PREFIX}/${encodeURIComponent(row['@id'])}/show`

    const jobId = getJobIdFromIri(row['@id'])

    let prefixedType = ''
    switch (type) {
      case PK_TYPE:
        prefixedType = '[INIT]'
        break
      case AUTO_AFTER_SALE_TYPE:
      case MANUAL_INITIAL_AFTER_SALE_TYPE:
        prefixedType = '[SAV]'
        break
      case MANUAL_DIAGNOSTIC_AFTER_SALE_TYPE:
        prefixedType = '[DIAG]'
        break
      case MANUAL_RESOLUTION_AFTER_SALE_TYPE:
        prefixedType = '[SAV-RES]'
        break
      default:
    }

    return (
      <TableRow
        key={row['@id']}
        hover
        className={classNames({
          [classes.urgent]: isClassUrgent,
        })}
      >
        <TableCell>
          <div className={classes.jobOrderIdContainer}>
            <CardMedia className={classes.imgPartner} image={partnerUrl} />
            <Link to={jobUrl} className={classes.linkJobOrder}>
              {jobId}
            </Link>
          </div>
        </TableCell>
        <TableCell>{prefixedType}</TableCell>
        <TableCell>{worksite.order.orderNumber}</TableCell>
        <TableCell>{worksite.order.store.name}</TableCell>
        <TableCell>
          <ViewMoreCell
            cellContent={translate(
              `resources.jobs.status.${substatus || status}`,
            )}
            bubbleContent={
              row.substatus === BLOCKED_RECEIPT ? row.reserves : null
            }
          />
        </TableCell>
        <TableCell className={classes.noWrap}>
          <LinkCustom
            href={`${JOB_PATH_PREFIX}/${encodeURIComponent(
              row['@id'],
            )}/show#${INCIDENTS}`}
            className={classes.link}
            disabled={!row.incidentsCount.inProgress}
          >
            {translate('resources.jobs.fields.incidents_in_progress', {
              count: row.incidentsCount.inProgress,
            })}
          </LinkCustom>
        </TableCell>
        <TableCell>
          {dateFormatter(worksite.order.checkoutCompletedAt, DATE_FORMAT)}
        </TableCell>
        <TableCell>{`${worksite.order.customer.firstName} ${worksite.order.customer.lastName}`}</TableCell>
        <TableCell>{worksite.address.postcode}</TableCell>
        <TableCell classes={{ root: classes.products }}>
          {products.map(product => (
            <span className={classes.span}>{product.name}</span>
          ))}
          {Object.keys(certificates).map(certificate => (
            <Icon
              classes={{ root: classes.icon }}
              icon={certificateIcons[certificate]}
            />
          ))}
        </TableCell>
        <TableCell>{dateFormatter(minDateHo, DATE_FORMAT)}</TableCell>
        <TableCell>
          {worksite.order.deliveryMode &&
            translate(`job.order.delivery_mode.${worksite.order.deliveryMode}`)}
        </TableCell>
        <TableCell className={classes.acceptedPro}>
          {acceptedPro && (
            <>
              {acceptedPro.firstName} {acceptedPro.lastName}
              <br />({acceptedPro.accountingNumber})
            </>
          )}
        </TableCell>
        <TableCell>{dateFormatter(minDatePro, DATE_FORMAT)}</TableCell>
        <TableCell>{dateFormatter(validatedAt, DATE_FORMAT)}</TableCell>
        <TableCell>{dateFormatter(proPaidAt, DATE_FORMAT)}</TableCell>
      </TableRow>
    )
  })

JobsSearchTableBody.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  translate: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    urgent: PropTypes.string.isRequired,
    noWrap: PropTypes.string.isRequired,
  }).isRequired,
}

export default compose(i18n, withStyles(styles))(JobsSearchTableBody)
