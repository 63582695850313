import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import { compose } from 'redux'
import { departmentsListByZoneSelector } from 'store/departments/departmentSelectors'
import { PackageActions } from 'store/products/packageActions'
import { PackageSelector } from 'store/products/packageSelector'
import { tradesListSelector } from 'store/trades/tradeSelectors'
import { getFormValuesFromFilterFirmsListForm } from 'store/firms/firmSelectors'
import { getDepartmentsList } from 'store/departments/departmentActions'
import { getTradesList } from 'store/trades/tradeActions'
import { resetFilterFirm } from 'store/firms/firmActions'
import { FILTER_FIRMS_LIST_FORM } from 'constants/forms'
import FirmsFilters from './FirmsFilters'

const mapStateToProps = state => ({
  initialValues: getFormValuesFromFilterFirmsListForm(state),
  departments: departmentsListByZoneSelector(state),
  packages: PackageSelector.typeListSelector()(state),
  trades: tradesListSelector(state),
})

const mapDispatchToProps = dispatch => ({
  getDepartments: () => dispatch(getDepartmentsList.request()),
  getPackages: () => dispatch(PackageActions.getTypeList().request()),
  getTrades: () => dispatch(getTradesList.request()),
  handleReset: () => dispatch(resetFilterFirm()),
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: FILTER_FIRMS_LIST_FORM,
    destroyOnUnmount: false,
  }),
)(FirmsFilters)
