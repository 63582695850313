import React, { useEffect, useState } from 'react'
import { compose } from 'redux'
import { reduxForm, Field } from 'redux-form'
import PropTypes from 'prop-types'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormGroup,
  Grid,
  Typography,
  withStyles,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import i18n from 'providers/i18n/I18nProvider'
import { GENERATE_ADDITIONNAL_INVOICE_FORM } from 'constants/forms'
import RenderSelectField from 'components/shared/Form/RenderSelectField'
import RenderTextField from 'components/shared/Form/RenderTextField'
import RenderRadio from 'components/shared/Form/RenderRadio'
import {
  LM_FRANCE,
  NATURE_TRAVEL_COMPENSATION,
  REBILLING_OPTIONS,
} from 'constants/invoices'
import styles from './GenerateAfterSaleInvoicesStyles'

const GenerateAfterSaleInvoicesForm = ({
  handleSubmit,
  setDialogOpened,
  translate,
  invoiceNaturesOptions,
  invoiceLineTypesOptions,
  providersOptions,
  amountsOptions,
  rebilling,
  rebillingCustomers,
  change,
  formValues,
  classes,
  addLine,
  rebillingCustomersOptions,
}) => {
  const [invoiceNature, setInvoiceNature] = useState('')
  const [invoiceLineType, setInvoiceLineType] = useState('')
  const [provider, setProvider] = useState('')
  const [rebillingCustomersList, setRebillingCustomersList] = useState([])
  const [amount, setAmount] = useState(undefined)
  const [errorAmount, setErrorAmount] = useState(false)
  const [minAmount, setMinAmount] = useState(undefined)
  const [maxAmount, setMaxAmount] = useState(undefined)
  const [filteredInvoiceLineTypes, setFilteredInvoiceLineTypes] = useState(
    invoiceLineTypesOptions,
  )
  const [
    rebillingTravelCompensation,
    setRebillingTravelCompensation,
  ] = useState(false)
  const isRebillingEnabled =
    provider !== '' &&
    rebilling.natures.includes(formValues?.invoiceNatures) &&
    rebilling.invoiceLineTypes.includes(formValues?.invoiceLineTypes)

  useEffect(() => {
    if (!invoiceNature) {
      return
    }
    const newFilteredInvoiceLineTypes = invoiceLineTypesOptions
      .filter(
        filtredInvoiceLineType =>
          filtredInvoiceLineType.natures.length === 0 ||
          filtredInvoiceLineType.natures.includes(invoiceNature),
      )
      .map(invoiceLineTypeItem => ({
        value: invoiceLineTypeItem.value,
        name: invoiceLineTypeItem.label,
      }))

    setFilteredInvoiceLineTypes(newFilteredInvoiceLineTypes)
    if (newFilteredInvoiceLineTypes.length === 1) {
      change('invoiceLineTypes', newFilteredInvoiceLineTypes[0].value)
      setInvoiceLineType(newFilteredInvoiceLineTypes[0].value)
    }
    if (
      !newFilteredInvoiceLineTypes.some(item => item.value === invoiceLineType)
    ) {
      change('invoiceLineTypes', '')
      setInvoiceLineType('')
    }
  }, [change, invoiceLineTypesOptions, invoiceNature, invoiceLineType])

  useEffect(() => {
    const filteredProviders = providersOptions.find(
      eachProvider =>
        (eachProvider.natures.length === 0 ||
          eachProvider.natures.includes(invoiceNature)) &&
        (eachProvider.invoiceLineTypes.length === 0 ||
          eachProvider.invoiceLineTypes.includes(invoiceLineType)),
    )?.values

    if (filteredProviders?.length === 1) {
      setProvider(filteredProviders[0].name)
      change('provider', filteredProviders[0].id)
    }
  }, [invoiceNature, invoiceLineType, providersOptions, change])

  useEffect(() => {
    if (!invoiceNature && !invoiceLineType && !provider) {
      return
    }

    const amountValue = amountsOptions.find(
      amountItem =>
        (amountItem.natures.length === 0 ||
          amountItem.natures.includes(invoiceNature)) &&
        (amountItem.invoiceLineTypes.length === 0 ||
          amountItem.invoiceLineTypes.includes(invoiceLineType)),
    )?.value

    if (amountValue?.exact) {
      setAmount(amountValue.exact)
      change('amount', amountValue.exact)
    }

    setErrorAmount(false)
    if (formValues.amount < minAmount || formValues.amount > maxAmount) {
      setErrorAmount(true)
      setAmount(null)
    }

    setMinAmount(amountValue?.min ?? undefined)
    setMaxAmount(amountValue?.max ?? undefined)
  }, [
    invoiceNature,
    invoiceLineType,
    provider,
    formValues,
    amountsOptions,
    minAmount,
    maxAmount,
    change,
  ])

  const naturesList = invoiceNaturesOptions.map(invoiceNatureItem => ({
    value: invoiceNatureItem.code,
    name: `${invoiceNatureItem.label}`,
  }))

  const providersInfo = []
  providersOptions.map(providerItem =>
    providerItem.values.map(item => {
      if (
        providerItem.natures.includes(formValues?.invoiceNatures) ||
        providerItem.invoiceLineTypes.includes(formValues?.invoiceLineTypes)
      ) {
        providersInfo.push({ value: item.id, name: item.name })
      }
      return providersInfo
    }),
  )

  const providersListInfo = providersInfo.map(info => ({
    value: info.value,
    name: `${info.name}`,
  }))

  const onSubmit = data => {
    addLine({
      ...data,
      rebilling: isRebillingEnabled ? data.rebilling : null,
    })
    setDialogOpened(false)
  }

  useEffect(() => {
    let rebillingOptionsList = []
    rebillingCustomers.forEach(customer => {
      if (
        customer.natures.includes(formValues?.invoiceNatures) &&
        customer.invoiceLineTypes.includes(formValues?.invoiceLineTypes)
      ) {
        rebillingOptionsList = [
          ...rebillingOptionsList,
          ...customer.values.map(({ id, name }) => ({
            value: id,
            name,
          })),
        ]
      }
    })

    if (rebillingOptionsList?.length === 1 && formValues.rebilling === 'true') {
      change('rebillingCustomer', rebillingOptionsList[0].value)
    }
    setRebillingCustomersList(rebillingOptionsList)
  }, [formValues, change, rebillingCustomers])

  useEffect(() => {
    setRebillingTravelCompensation(false)
    if (
      invoiceNature === NATURE_TRAVEL_COMPENSATION &&
      formValues?.rebilling === 'true' &&
      rebillingCustomersOptions[formValues?.rebillingCustomer] === LM_FRANCE
    ) {
      setRebillingTravelCompensation(true)
    }
  }, [invoiceNature, formValues, rebillingCustomersOptions])

  return (
    <Dialog
      open
      onClose={() => setDialogOpened(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <form onSubmit={handleSubmit(onSubmit)} name="additionalInvoiceForm">
        <Grid container>
          <DialogTitle id="alert-dialog-title">
            {translate('afterSale.invoices.select_title')}
          </DialogTitle>
          <DialogContent>
            <Field
              name="invoiceNatures"
              label={translate('afterSale.invoices.select.label_reasons')}
              component={RenderSelectField}
              choices={naturesList}
              onChange={e => setInvoiceNature(e.target.value)}
              allowEmpty
            />
            {invoiceNature !== '' && (
              <Field
                name="invoiceLineTypes"
                label={translate(
                  'afterSale.invoices.select.label_benefit_to_be_paid',
                )}
                component={RenderSelectField}
                choices={filteredInvoiceLineTypes}
                onChange={e => setInvoiceLineType(e.target.value)}
                allowEmpty
              />
            )}
            {invoiceNature !== '' && invoiceLineType !== '' && (
              <Field
                name="provider"
                label={translate('afterSale.invoices.select.label_paid_at')}
                component={RenderSelectField}
                choices={providersListInfo}
                onChange={e => setProvider(e.target.value)}
                allowEmpty
              />
            )}
            {invoiceNature !== '' &&
              invoiceLineType !== '' &&
              provider !== '' && (
                <Field
                  name="amount"
                  label={translate('afterSale.invoices.select.label_amount')}
                  component={RenderTextField}
                  type="number"
                  allowEmpty
                  disabled={invoiceNature === NATURE_TRAVEL_COMPENSATION}
                />
              )}
            {errorAmount && (
              <DialogContentText>
                <Typography className={classes.urgentWord}>
                  {translate('app.generic_error')}
                </Typography>
              </DialogContentText>
            )}
            {isRebillingEnabled && (
              <FormGroup className={classes.checkbox}>
                <Field
                  name="rebilling"
                  label={translate('afterSale.invoices.rebilling')}
                  component={RenderRadio}
                  choices={REBILLING_OPTIONS}
                />
              </FormGroup>
            )}
            {isRebillingEnabled && formValues?.rebilling === 'true' && (
              <Field
                name="rebillingCustomer"
                label={translate('afterSale.invoices.rebilling_pro')}
                component={RenderSelectField}
                choices={rebillingCustomersList}
                allowEmpty
              />
            )}
            {rebillingTravelCompensation && (
              <Alert severity="warning" className={classes.warningAlert}>
                {translate('afterSale.invoices.travelCompensation.alert')}
              </Alert>
            )}
          </DialogContent>
          {invoiceNature !== '' &&
            invoiceLineType !== '' &&
            provider !== '' &&
            amount !== '' && (
              <Grid
                container
                direction="row"
                alignItems="center"
                alignContent="center"
              >
                <DialogActions align="center">
                  <Button
                    type="submit"
                    autoFocus
                    variant="contained"
                    color="primary"
                    disabled={
                      errorAmount ||
                      !formValues.amount ||
                      (isRebillingEnabled &&
                        !formValues?.rebilling &&
                        !formValues?.rebillingCustomer)
                    }
                  >
                    {rebillingTravelCompensation
                      ? translate(
                          'afterSale.manual.button_validate_and_update_credentials_file',
                        )
                      : translate(
                          'afterSale.manual.button_validate_and_continue',
                        )}
                  </Button>
                </DialogActions>
              </Grid>
            )}
        </Grid>
      </form>
    </Dialog>
  )
}

GenerateAfterSaleInvoicesForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  setDialogOpened: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  addLine: PropTypes.func.isRequired,
  invoiceNaturesOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  invoiceLineTypesOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  providersOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  amountsOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  rebilling: PropTypes.shape({
    natures: PropTypes.arrayOf(PropTypes.string),
    invoiceLineTypes: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  rebillingCustomers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  formValues: PropTypes.arrayOf(PropTypes.shape({})),
  classes: PropTypes.shape({
    urgentWord: PropTypes.string,
    warningAlert: PropTypes.string,
    checkbox: PropTypes.string,
  }).isRequired,
  rebillingCustomersOptions: PropTypes.shape({}).isRequired,
}

GenerateAfterSaleInvoicesForm.defaultProps = {
  formValues: undefined,
}

export default compose(
  i18n,
  withStyles(styles),
  reduxForm({
    form: GENERATE_ADDITIONNAL_INVOICE_FORM,
    enableReinitialize: false,
  }),
)(GenerateAfterSaleInvoicesForm)
